import React, { useState, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Divider } from "@mui/material";
import useSnackBar from "../../contexts/SnackBar/useSnackBar.js";
import { db } from "../../models/db.js";
import SaveIcon from "@mui/icons-material/Save";

export default function EditTripModal({ isOpen, onClose, trip }) {
  const [name, setName] = useState(trip.title);
  const [note, setNote] = useState(trip.note || "");
  const { enqueueSnackbar } = useSnackBar();

  useEffect(() => {
    setName(trip.title);
  }, [trip]);

  const clearForm = () => {
    setName("");
  };

  const isValid = name.length > 3;

  const saveData = async () => {
    const noteText = note.trim();
    const hasNoteText = noteText.length > 0;
    const updatedTrip = await db.updateTrip({ title: name.trim(), note: hasNoteText ? noteText : undefined }, trip);
    enqueueSnackbar({ message: `${updatedTrip.title || "Trip"} Updated`, variant: "success" });

    return trip;
  };

  const onPrimaryClick = async () => {
    if (!isValid) return;
    await saveData();
    onClose();
  };

  const onCloseClick = () => {
    onClose();
    clearForm();
  };

  return (
    <Dialog open={isOpen} onClose={onCloseClick}>
      <DialogTitle>Edit Trip</DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          label="Name"
          fullWidth
          required
          value={name}
          autoFocus
          onChange={(event) => setName(event.target.value)}
        />

        <TextField label="Note" multiline fullWidth value={note} onChange={(event) => setNote(event.target.value)} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button
          disabled={!isValid}
          onClick={onPrimaryClick}
          startIcon={<SaveIcon fontSize="small" />}
          color="primary"
          variant="contained"
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}
