export default function emptyPaymentObject() {
  const now = new Date();

  return {
    id: self.crypto.randomUUID(),
    createdAt: now,
    updatedAt: now,
    paymentDate: null,
    paymentMethod: null,

    pointsPaidAsString: "",
    pointsPaidIsValid: true,
    pointsPaid: undefined,

    pricePaidAsString: "",
    pricePaidIsValid: true,
    pricePaid: undefined,

    isPaid: false,
    isPrinciple: false,

    priceAsString: "",
    priceIsValid: true,
    price: undefined,

    priceCurrency: "GBP",
    title: "",
    description: "",
    loyaltyProgram: null,
    isPriceEstimated: false,
    features: [],
  };
}

export function paymentObjectFromBooking(booking) {
  const result = {
    ...emptyPaymentObject(),
    pointsPaidAsString: `${booking.pointsPaid?.toString() ?? ""}`,
    pointsPaidIsValid: true,
    pointsPaid: booking.pointsPaid ?? undefined,

    pricePaidAsString: `${booking.pricePaid?.toString() ?? ""}`,
    pricePaidIsValid: true,
    pricePaid: booking.pricePaid ?? undefined,

    priceAsString: `${booking.price?.toString() ?? ""}`,
    priceIsValid: true,
    price: booking.price ?? undefined,
    priceCurrency: booking.priceCurrency || "GBP",
  };

  result.createdAt = booking.createdAt;
  result.isPaid = booking.isPaid;
  result.priceCurrency = booking.priceCurrency;
  result.isPriceEstimated = booking.isPriceEstimated;
  result.paymentMethod = booking.paymentMethod;

  return result;
}
