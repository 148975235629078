import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React from "react";
import { AutoCompleteListBox, AutoCompleteListItem } from "./AutocompleteList.js";
const filter = createFilterOptions({
  stringify: (option) => `${option.id} ${option.title}`,
});

export default function CreatableAutocomplete({
  label,
  value,
  onChange,
  options,
  clearOnBlur,
  variant,
  sx,
  useValue,
  autoSelect,
  required,
  loading,
  error,
  renderOption,
  disableClearable,
  disabled,
  fullWidth,
  forcePopupIcon,
  endAdornment,
}) {
  const onValueChange = (event, newValue) => {
    if (typeof newValue === "string") {
      onChange({
        title: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      onChange({
        title: newValue.inputValue,
      });
    } else {
      onChange(newValue);
    }
  };

  if (loading) return null;

  let valueToProxy = value;

  if (value && !value.title && value.id) {
    // we are loading by ID, we need to reload the existing item
    valueToProxy = options.find((option) => option.id === value.id) || value;
  }

  const renderOptionDefault = (props, option) => {
    return (
      <AutoCompleteListItem {...props} key={props.key}>
        {option.id && useValue ? option.id : option.title}
      </AutoCompleteListItem>
    );
  };

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    // Suggest the creation of a new value
    const isExisting = options.some(
      (option) => inputValue === option.title || inputValue.toUpperCase() === option.id || inputValue === option.id,
    );
    if (inputValue !== "" && !isExisting) {
      const newValueModified = useValue && inputValue.length <= 4 ? inputValue.toUpperCase() : inputValue;
      filtered.push({
        inputValue: newValueModified,
        title: `Add "${newValueModified}"`,
      });
    }

    return filtered;
  };
  return (
    <Autocomplete
      forcePopupIcon={forcePopupIcon}
      value={valueToProxy}
      required={required}
      onChange={onValueChange}
      autoSelect={autoSelect}
      ListboxComponent={AutoCompleteListBox}
      filterOptions={filterOptions}
      selectOnFocus
      clearOnBlur={clearOnBlur}
      disableClearable={disableClearable}
      handleHomeEndKeys
      disabled={disabled}
      options={options}
      isOptionEqualToValue={(option, value) => {
        if (option === value) return true;
        if (option?.id === value?.id) return true;
        if (option?.id && option.id === value) return true;

        return false;
      }}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }

        if (useValue && option.id) return option.id;

        // Regular option
        return option.title;
      }}
      renderOption={renderOption || renderOptionDefault}
      sx={sx}
      freeSolo
      fullWidth={fullWidth ?? true}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            InputLabelProps={{
              ...params.InputLabelProps,
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {endAdornment}
                </>
              ),
            }}
            variant={variant || "standard"}
            margin="none"
            error={error}
            label={label}
            required={required}
            // slotProps={{
            //   ...params.slotProps,
            //   inputValue: {
            //     shrink: true,
            //   },
            // }}
          />
        );
      }}
      slotProps={{
        paper: { elevation: 8 },
        clearIndicator: {
          sx: {
            visibility: "visible",
          },
        },
      }}
    />
  );
}
