import BookingValidator from "./BookingValidator.js";
import { TRAVEL, STAY, SERVICE } from "./BookingConstants.js";
import getSegmentStationIds from "./getSegmentStationIds.js";
/**
 * Represents a Booking, this may contain one or more hotel, train, flights, or service, in a single booking. It's meant
 * as a single reservation with a provider.
 */
export default class Booking {
  constructor() {
    Object.defineProperty(this, "segmentCarrierIds", {
      enumerable: true,
      get: () => {
        if (!this.segments || !this.segments.length) return [];
        return [
          ...this.segments
            .map((segment) => segment.carrier?.id)
            .reduce((acc, id) => {
              if (id) acc.add(id);
              return acc;
            }, new Set()),
        ];
      },
    });

    Object.defineProperty(this, "segmentStationIds", {
      enumerable: true,
      get: () => {
        return getSegmentStationIds(this.segments);
      },
    });
  }

  generateTitle({ segments, stays }) {
    if (this.type.includes(SERVICE)) {
      return this.note;
    }

    const items = [...(stays || []).map((stay) => stay.title), ...(segments || []).map((segment) => segment.title)];

    return items.join(", ");
  }

  setTitle(data) {
    this.title = this.generateTitle(data);
  }

  /**
   * The leading carrier object. Clone.
   * @type {{id: String, title: String}|undefined}
   */
  leadingCarrier;

  /**
   * The leading stay brand object. Clone.
   * @type {{id: String, title: String}|undefined}
   */
  leadingStayBrand;

  /**
   * The leading stay location object. Clone.
   * @type {{id: String, title: String, timeZone: String|undefined}|undefined}
   */
  leadingStayLocation;

  /**
   * A booking preview title.
   * @type String
   */
  title;

  /**
   * The booking ID
   * @type String
   */
  id;

  /**
   * The booking reference
   * @type String
   */
  bookingReference;

  /**
   * When the booking was created on the platform (UTC).
   * @type Date
   */
  createdAt;

  /**
   * When the booking was last modified on the platform (UTC).
   * @type {Date}
   */
  updatedAt;

  /**
   * When the booking was actually placed with the vendor (user timezone).
   * @type {Date|undefined}
   */
  bookingDate;

  /**
   * The sortable relevance starting date, if any, determined by the departure or check-in dates.
   * @type {Date|undefined}
   */
  startDate;

  /**
   * The sortable relevance ending date, if any, determined by the arrival or check-out dates.
   * @type {Date|undefined}
   */
  endDate;

  /**
   * The booking type.
   * @type {String[]}
   */
  type;

  get isTravel() {
    return this.type.includes(TRAVEL);
  }

  get isService() {
    return this.type.includes(SERVICE);
  }

  get isStay() {
    return this.type.includes(STAY);
  }

  /**
   * The points paid, if any.
   * @type Number
   * @deprecated
   */
  pointsPaid;

  /**
   * The price actually paid on card in home currency.
   * @type Number
   */
  pricePaid;

  /**
   * The price paid in the vendor currency
   * @type Number
   */
  price;

  /**
   * The currency code of the `price`. i.e. `GBP`
   * @type String
   */
  priceCurrency;

  /**
   * The status of this booking. ENUM.
   * @type String
   */
  status;

  /**
   * Indicates if the segments are a complete return journey.
   * @type Boolean
   */
  isReturn;

  /**
   * Indicates if the segments are connected in a route with no airport changes during a layover.
   * @type {Boolean|undefined}
   */
  isConnectedTravelSequence;

  /**
   * Indicates if this booking is entirely ignored, i.e. it was paid, but was not travelled/stayed. Perhaps it was missed, or lost. Use unclear.
   * @type {Boolean|undefined}
   * @deprecated
   */
  isSkipped;

  /**
   * The ID of the booking agent, if not direct. Clone.
   * @type {{id: String, title: String}|undefined}
   */
  agent;

  /**
   * The method of payment.
   * @type {{id: String}|undefined}
   * @deprecated
   */
  paymentMethod;

  /**
   * If this is stay type booking, the total number of nights
   * @type {Number|undefined}
   */
  _nights;

  /**
   * If the `pricePaid` is set, and there is a `_nights` value. The average price per night in home currency
   * @type {Number|undefined}
   */
  pricePaidPerNight;

  /**
   * If the `price` is set, and there is a `_nights` value. The average price per night in local currency
   * @type {Number|undefined}
   */
  pricePerNight;

  /**
   * The Trip details. Clone.
   * @type {{id: String, title: String}|undefined}
   */
  trip;

  /**
   * If this booking can be cancelled.
   * @type Boolean
   */
  canBeCancelled;

  /**
   * If this booking can be cancelled, when.
   * @type Date
   */
  cancelBeforeDate;

  /**
   * Optional free-text.
   * @type String
   */
  note;

  /**
   * If this booking has been fully paid.
   * @type {Boolean|undefined}
   */
  isPaid;

  /**
   * If this booking price has been estimated, or confirmed.
   * @type Boolean
   */
  isPriceEstimated;

  /**
   * The related segments (travel steps)
   * @type {Object[]}
   */
  segments;

  /**
   * The related stays
   * @type {Object[]}
   */
  stays;

  /**
   * The related financial payments
   * @type {BookingPayment[]}
   */
  payments;

  /**
   * Indicates if this Booking and its data is valid.
   * @return {boolean}
   */
  get isValid() {
    const { error, value } = BookingValidator.validate(this, { abortEarly: false });
    if (error === undefined) {
      return true;
    }
    console.log(error, value);
    return false;
  }
}
