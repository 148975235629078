/**
 * Generate a nice looking ID
 * @param {String} title
 * @return {String}
 */
export default function generateIdFromTitle(title) {
  if (!title) return undefined;
  return title
    .trim()
    .replace(/([-_])/gi, " ")
    .toUpperCase()
    .replace(/\s+/g, " ")
    .split(" ")
    .join("_");
}
