import AddIcon from "@mui/icons-material/Add";
import Alert from "@mui/material/Alert";
import React, { useState } from "react";
import { Button, DialogActions, DialogContent, Divider } from "@mui/material";
import useLoyaltyPrograms from "../../../contexts/LoyaltyPrograms/useLoyaltyPrograms.js";
import filterDefaultOptions from "./filterProgramOptions.js";
import LoyaltyProgramsAutocomplete from "./LoyaltyProgramsAutocomplete.js";

export default function AddLoyaltyProgramTab({ onAdd, isEmpty, selectedIds }) {
  const { loyaltyPrograms } = useLoyaltyPrograms();

  const optionsToShow = filterDefaultOptions({ loyaltyPrograms: loyaltyPrograms || [], selectedIds });
  const defaultLoyaltyProgram = (optionsToShow || [])[0] || null;

  const [selectedLoyaltyProgram, setSelectedLoyaltyProgram] = useState(defaultLoyaltyProgram);

  const onLoyaltyProgramSelected = (newValue) => {
    setSelectedLoyaltyProgram(newValue);
  };

  const onPrimaryClick = () => {
    onAdd(selectedLoyaltyProgram);
  };

  const isValid = Boolean(selectedLoyaltyProgram);
  return (
    <>
      <DialogContent>
        {isEmpty && (
          <Alert severity="info" sx={{ marginBottom: 2 }}>
            No loyalty program activity has been added.
          </Alert>
        )}
        <LoyaltyProgramsAutocomplete
          value={selectedLoyaltyProgram}
          onChange={onLoyaltyProgramSelected}
          selectedIds={selectedIds}
        />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ justifyContent: "flex-start" }}>
        <Button
          onClick={onPrimaryClick}
          startIcon={<AddIcon fontSize="small" />}
          disabled={!isValid}
          color="primary"
          variant="contained"
        >
          Add
        </Button>
      </DialogActions>
    </>
  );
}
