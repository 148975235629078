import BookingPayment from "../../models/BookingPayment.js";

export default function PaymentEditorReducerValidator(newData) {
  const newBookingPaymentInstance = new BookingPayment(newData);

  if (newBookingPaymentInstance.paymentMethod && !newBookingPaymentInstance.paymentMethod.id) {
    newBookingPaymentInstance.paymentMethod = { id: newBookingPaymentInstance.paymentMethod.title };
  }

  newData.isEmpty = newBookingPaymentInstance.isEmpty;
  newData.isValid = newBookingPaymentInstance.isValid;

  return newData;
}
