import Joi from "joi";
import AircraftValidator from "./AircraftValidator.js";

const SegmentValidator = Joi.object({
  id: Joi.string().optional(),
  trip: Joi.object({
    id: Joi.string().optional(),
    title: Joi.string().optional(),
  }).optional(),
  booking: Joi.object({
    id: Joi.string().optional(),
    status: Joi.string().optional(),
    bookingReference: Joi.string().optional().empty(""),
    bookingDate: Joi.date().optional(),
  }).optional(),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
  departureDate: Joi.date().optional(),
  isLeadingCarrier: Joi.boolean().optional(),
  isSkipped: Joi.boolean().optional(),
  isSecondaryPassenger: Joi.boolean().optional(),
  isOvernight: Joi.boolean().optional(),
  origin: Joi.object({
    id: Joi.string().required(),
    title: Joi.string().required(),
    timeZone: Joi.string().optional(),
    lat: Joi.number().optional(),
    lon: Joi.number().optional(),
  }).required(),
  originTerminal: Joi.string().optional().example("2"),
  destination: Joi.object({
    id: Joi.string().required(),
    title: Joi.string().required(),
    timeZone: Joi.string().optional(),
    lat: Joi.number().optional(),
    lon: Joi.number().optional(),
  }).required(),
  destinationTerminal: Joi.string().optional().example("3"),
  distanceInKm: Joi.number().optional(),
  durationInMinutes: Joi.number().positive().optional(),
  departureTimeUtc: Joi.date().optional(), // Parse from `2025-02-11 17:40Z`
  arrivalTimeUtc: Joi.date().optional(),
  // TODO: Parse the string, or make obsolete.
  departureTimeLocal: Joi.string().optional().example("2025-02-11 14:40-03:00"),
  arrivalTimeLocal: Joi.string().optional().example("2025-02-12 05:55+01:00"),
  eTicket: Joi.string().optional(),
  flightNumber: Joi.string().optional().uppercase(),
  carrierReference: Joi.string().optional(),
  classCode: Joi.string().uppercase().optional(),
  note: Joi.string().optional(),
  carrier: Joi.object({
    id: Joi.string().required(),
    title: Joi.string().required(),
    type: Joi.string().required(),
  }).required(),
  loyaltyProgramIds: Joi.array().items(Joi.string()).required(),
  loyaltyProgramActivities: Joi.array().items(Joi.object()).required(),
  aircraft: AircraftValidator.optional(),
}).label("Segment");

export default SegmentValidator;
