export const UPGRADE_CLASS = "UPGRADE_CLASS";
export const SEAT_SELECTION = "SEAT_SELECTION";
export const PRIORITY_BOARDING = "PRIORITY_BOARDING";
export const MEAL = "MEAL";
export const BREAKFAST = "BREAKFAST";
export const CHECKED_BAGGAGE = "CHECKED_BAGGAGE";
export const LATE_CHECKOUT = "LATE_CHECKOUT";
export const EARLY_CHECKIN = "EARLY_CHECKIN";
export const AIRPORT_TRANSFER = "AIRPORT_TRANSFER";

const BookingPaymentConstants = [
  UPGRADE_CLASS,
  SEAT_SELECTION,
  PRIORITY_BOARDING,
  MEAL,
  BREAKFAST,
  CHECKED_BAGGAGE,
  LATE_CHECKOUT,
  EARLY_CHECKIN,
  AIRPORT_TRANSFER,
];

export default BookingPaymentConstants;
