import TextField from "@mui/material/TextField";
import React from "react";
import useStations from "../../contexts/Stations/useStations.js";
import CreatableAutocomplete from "./CreatableAutocomplete.js";
import Stack from "@mui/material/Stack";

export default function StationsAutocomplete({ value, onChange, label, required, valueName, helperText }) {
  const { stations } = useStations();

  let titleValue = "";

  if (value) {
    if (value.id) {
      // pre-saved value
      titleValue = value.title;
    } else {
      // unsaved
      titleValue = valueName;
    }
  }

  return (
    <Stack direction="row" spacing={1}>
      <CreatableAutocomplete
        value={value}
        onChange={onChange}
        options={stations}
        label={label}
        sx={{ width: 75, minWidth: 75 }}
        useValue
        required={required}
        disableClearable
        clearOnBlur={false}
      />
      <TextField
        label={`${label} Name`}
        value={titleValue}
        disabled={value?.id?.length > 0}
        onChange={(event) => {
          onChange(value, event.target.value);
        }}
        variant="standard"
        margin="none"
        sx={{ maxWidth: 160 }}
        required={required}
        helperText={helperText}
      />
    </Stack>
  );
}
