import Big from "big.js";
import { addDays, isAfter, isBefore } from "date-fns";
import isConnectedTravelSequence from "../../lib/isConnectedTravelSequence.js";
import isReturnBooking from "../../lib/isReturnBooking.js";
import now from "../../lib/now.js";
import Agent from "../../models/Agent.js";
import Booking from "../../models/Booking.js";
import { SERVICE, STAY, TRAVEL } from "../../models/BookingConstants.js";
import BookingPayment from "../../models/BookingPayment.js";
import Carrier from "../../models/Carrier.js";
import { FLIGHT } from "../../models/CarrierConstants.js";
import LoyaltyProgramActivity from "../../models/LoyaltyProgramActivity.js";
import PaymentMethod from "../../models/PaymentMethod.js";
import Segment from "../../models/Segment.js";
import Station from "../../models/Station.js";
import Stay from "../../models/Stay.js";
import StayBrand from "../../models/StayBrand.js";
import StayLocation from "../../models/StayLocation.js";

export function isCollectionInValid(collection) {
  if (!collection?.length) return false;
  return Boolean(collection.find((segment) => segment.isValid === false));
}

export default function generateData(state) {
  const createdAt = now();

  const { bookingDate, pricePaid, price, priceCurrency, cancelBeforeDate } = state;

  const result = {};

  if (state.agent && !state.agent.id) {
    // we have a new agent to save
    result.agent = new Agent({
      createdAt,
      updatedAt: createdAt,
      title: state.agent.title,
    });
  }

  const _agent = result.agent || state.agent;
  const booking = new Booking();
  booking.bookingDate = bookingDate;

  if (cancelBeforeDate) {
    booking.cancelBeforeDate = cancelBeforeDate;
  }
  booking.canBeCancelled = Boolean(cancelBeforeDate);
  booking.agent = _agent ? { id: _agent.id, title: _agent.title } : undefined;

  booking.createdAt = state.createdAt;
  booking.updatedAt = createdAt;
  booking.bookingReference = state.bookingReference || undefined;

  booking.type = [];
  booking.status = state.status;
  if (state.trip) booking.trip = { id: state.trip.id, title: state.trip.title };
  booking.id = state.id;
  booking.note = state.note || undefined;

  const stations = [];
  const carriers = [];
  const stayBrands = [];
  const stayLocations = [];

  result.segments = state.segments.map((segment) => {
    let newOriginStation;
    let newDestinationStation;
    if (segment.origin && !segment.origin.id) {
      const existingNewStation = stations.find((station) => station.title === segment.origin.title);
      if (existingNewStation) {
        // We're already creating the station
        newOriginStation = existingNewStation;
      } else {
        // A new origin station
        newOriginStation = new Station({
          id: segment.origin.title,
          title: segment.originTitle || segment.origin.title,
          createdAt,
          updatedAt: createdAt,
        });
        stations.push(newOriginStation);
      }
    }

    if (segment.destination && !segment.destination.id) {
      const existingNewStation = stations.find((station) => station.title === segment.destination.title);
      if (existingNewStation) {
        // We're already creating the station
        newDestinationStation = existingNewStation;
      } else {
        // A new destination station
        newDestinationStation = new Station({
          id: segment.destination.title,
          title: segment.destinationTitle || segment.destination.title,
          createdAt,
          updatedAt: createdAt,
        });
        stations.push(newDestinationStation);
      }
    }

    const newSegment = new Segment({
      ...segment,
      booking,
      trip: booking.trip,
      origin: newOriginStation ? { id: newOriginStation.id, title: newOriginStation.title } : segment.origin,
      destination: newDestinationStation
        ? { id: newDestinationStation.id, title: newDestinationStation.title }
        : segment.destination,
      loyaltyProgramActivities: segment.loyaltyProgramActivities.map((loyaltyProgramActivity) =>
        new LoyaltyProgramActivity(loyaltyProgramActivity).toJSON(),
      ),
    });

    if (segment.carrier && !segment.carrier.id) {
      // A new carrier
      const newCarrier =
        carriers.find((carrier) => carrier.title === segment.carrier.title) ||
        new Carrier({
          title: segment.carrier.title,
          createdAt,
          updatedAt: createdAt,
        });
      newSegment.carrier = { id: newCarrier.id, title: newCarrier.title, type: newCarrier.type };
      carriers.push(newCarrier);
    }

    if (segment.isLeadingCarrier && newSegment.carrier) {
      booking.leadingCarrier = {
        id: newSegment.carrier.id,
        title: newSegment.carrier.title,
        type: newSegment.carrier.type,
      };
    }

    return newSegment;
  });

  booking.segments = result.segments;
  if (booking.segments.length) {
    booking.type.push(TRAVEL);
    booking.startDate = result.segments.reduce((acc, segment) => {
      if (!segment.departureDate || segment.isSkipped) return acc;
      if (!acc || isBefore(segment.departureDate, acc)) return segment.departureDate;
      return acc;
    }, undefined);
    booking.endDate = result.segments.reduce((acc, segment) => {
      if (!segment.departureDate || segment.isSkipped) return acc;
      const compareDate = segment.isOvernight ? addDays(segment.departureDate, 1) : segment.departureDate;
      if (!acc || isAfter(compareDate, acc)) return compareDate;
      return acc;
    }, undefined);
  }

  result.stays = state.stays.map((stay) => {
    const newStay = new Stay({
      ...stay,
      booking,
      trip: booking.trip,
      loyaltyProgramActivities: stay.loyaltyProgramActivities.map((loyaltyProgramActivity) =>
        new LoyaltyProgramActivity(loyaltyProgramActivity).toJSON(),
      ),
    });

    if (stay.brand && !stay.brand.id) {
      // A new Stay Brand
      const newStayBrand =
        stayBrands.find((stayBrand) => stayBrand.title === stay.brand.title.trim()) ||
        new StayBrand({
          title: stay.brand.title.trim(),
          createdAt,
          updatedAt: createdAt,
        });
      newStay.brand = { id: newStayBrand.id, title: newStayBrand.title };
      stayBrands.push(newStayBrand);
    } else if (stay.brand && stay.brand.id) {
      newStay.brand = { id: stay.brand.id, title: stay.brand.title };
    }

    if (stay.location && !stay.location.id) {
      // A new Stay Location
      const newStayLocation =
        stayLocations.find((stayLocation) => stayLocation.title === stay.location.title.trim()) ||
        new StayLocation({
          title: stay.location.title.trim(),
          createdAt,
          updatedAt: createdAt,
          id: self.crypto.randomUUID(),
          brand: {
            id: newStay.brand.id,
            title: newStay.brand.title,
          },
        });
      newStay.location = { id: newStayLocation.id, title: newStayLocation.title, timeZone: newStayLocation.timeZone };
      stayLocations.push(newStayLocation);
    } else if (stay.location && stay.location.id) {
      newStay.location = { id: stay.location.id, title: stay.location.title, timeZone: stay.location.timeZone };
    }

    booking._nights = (booking._nights || 0) + (newStay.nights || 0);
    console.log(newStay);
    return newStay;
  });

  booking.stays = result.stays;

  result.paymentMethods = [];

  result.payments = state.payments.map((payment) => {
    let paymentMethod;
    if (payment.paymentMethod && !payment.paymentMethod.id) {
      // new payment method
      // Payment method already created?

      let newPaymentMethod = result.paymentMethods.find((item) => item.id === payment.paymentMethod.title);

      if (!newPaymentMethod) {
        // Payment method is new, so set it up for saving
        newPaymentMethod = new PaymentMethod({ id: payment.paymentMethod.title });
        result.paymentMethods.push(newPaymentMethod);
      }
      paymentMethod = { id: newPaymentMethod.id };
    } else if (payment.paymentMethod && payment.paymentMethod.id) {
      paymentMethod = { id: payment.paymentMethod.id };
    }

    return new BookingPayment({
      ...payment,
      paymentMethod,
    });
  });

  booking.payments = result.payments;

  booking.price = price;
  booking.priceCurrency = priceCurrency || undefined;
  booking.isPaid = state.isPaid;
  booking.isPriceEstimated = booking.priceCurrency === "GBP" ? false : state.isPriceEstimated;
  booking.pricePaid = pricePaid;

  booking.pointsPaid = undefined;
  booking.paymentMethod = undefined;

  if (booking.stays.length) {
    // TODO: Handle scenario when a booking has stays at multiple brands/locations in a single booking.
    // See also: *segmentCarrierIds
    booking.leadingStayBrand = booking.stays[0].brand;
    booking.leadingStayLocation = booking.stays[0].location;
    booking.type.push(STAY);

    booking.startDate = result.stays.reduce((acc, stay) => {
      if (!stay.checkInDate || stay.isSkipped) return acc;
      if (!acc || isBefore(stay.checkInDate, acc)) return stay.checkInDate;
      return acc;
    }, undefined);
    booking.endDate = result.stays.reduce((acc, stay) => {
      if (!stay.checkOutDate || stay.isSkipped) return acc;
      if (!acc || isAfter(stay.checkOutDate, acc)) return stay.checkOutDate;
      return acc;
    }, undefined);
  }

  if (booking._nights && booking.pricePaid) {
    booking.pricePaidPerNight = new Big(booking.pricePaid).div(booking._nights).round(2).toNumber();
  }

  if (booking._nights && booking.price) {
    booking.pricePerNight = new Big(booking.price).div(booking._nights).round(2).toNumber();
  }

  if (stations.length) {
    result.stations = stations;
  }

  if (carriers.length) {
    result.carriers = carriers;
  }

  if (stayBrands.length) {
    result.stayBrands = stayBrands;
  }

  if (stayLocations.length) {
    result.stayLocations = stayLocations;
  }

  if (state.segmentsToDelete?.length) {
    result.segmentsToDelete = state.segmentsToDelete;
  }

  if (state.staysToDelete?.length) {
    result.staysToDelete = state.staysToDelete;
  }

  if (!booking.type.length) {
    booking.type.push(SERVICE);
  }

  booking.setTitle(result);

  booking.isReturn = isReturnBooking(booking);
  booking.isConnectedTravelSequence = isConnectedTravelSequence(booking);
  result.booking = booking;

  if (!result.booking.isValid) {
    console.log("NOT VALID - BOOKING");
    return null;
  }

  if (result.agent && !result.agent.isValid) {
    console.log("NOT VALID - AGENT");
    return null;
  }

  if (isCollectionInValid(result.segments)) {
    console.log("NOT VALID - SEGMENT");
    return null;
  }

  if (isCollectionInValid(result.stays)) {
    console.log("NOT VALID - STAY");
    return null;
  }

  if (isCollectionInValid(result.stations)) {
    console.log("NOT VALID - STATION");
    return null;
  }

  if (isCollectionInValid(result.carriers)) {
    console.log("NOT VALID - CARRIER");
    return null;
  }

  if (isCollectionInValid(result.stayBrands)) {
    console.log("NOT VALID - STAY BRAND");
    return null;
  }

  if (isCollectionInValid(result.stayLocations)) {
    console.log("NOT VALID - STAY LOCATION");
    return null;
  }

  result.flights = result.segments.filter((segment) => segment.carrier.type === FLIGHT);

  return result;
}
