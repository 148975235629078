import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import React from "react";
import { useIntl } from "react-intl";
import getSegmentFullTitle from "../../models/getSegmentFullTitle.js";
import CopyToClipboardText from "../CopyToClipboardText.js";
import { BookingLink } from "../GenericBookingsTable/BookingTitleLink.js";
import SegmentDistanceLabel from "../GenericBookingsTable/SegmentDistanceLabel.js";
import SegmentStatusLabel from "../GenericBookingsTable/SegmentStatusLabel.js";
import StatusLabel from "../GenericBookingsTable/StatusLabel.js";
import { CarrierLink } from "../GenericBookingsTable/SupplierTitleLink.js";
import TextWrapper from "../GenericBookingsTable/TextWrapper.js";
import TripLink from "../GenericBookingsTable/TripLink.js";
import GenericLoyaltyProgramActivityColumn from "../LoyaltyProgramActivityList/GenericLoyaltyProgramActivityColumn.js";

export default function GenericSegmentRow({ segment, linkState, showOnlySource }) {
  const { formatDate } = useIntl();

  const primaryLoyaltyProgram = segment.loyaltyProgramActivities?.[0];

  return (
    <TableRow hover>
      <TableCell
        sx={{
          whiteSpace: "nowrap",
        }}
      >
        {formatDate(segment.departureDate, {
          year: "numeric",
          month: "short",
          day: "numeric",
          weekday: "short",
        })}
      </TableCell>
      <TableCell>
        <CarrierLink id={segment.carrier.id} title={segment.carrier.title} />
      </TableCell>
      <TableCell>
        <BookingLink
          id={segment.booking.id}
          title={getSegmentFullTitle({
            segment,
            leadingCarrier: null,
            hideStationIds: false,
            hideCarrier: true,
          })}
          state={linkState}
        />

        <StatusLabel booking={segment.booking} inline />
        <SegmentStatusLabel segment={segment} inline />
        <SegmentDistanceLabel segment={segment} inline />

        {/*<BookingDescriptionText booking={booking} />*/}
      </TableCell>

      <TableCell>
        <TextWrapper width="100px">
          <CopyToClipboardText
            value={segment.carrierReference || segment.booking?.bookingReference}
            sx={{
              maxWidth: "inherit",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              display: "inline-block",
            }}
          />
        </TextWrapper>
      </TableCell>

      <TableCell>
        <TextWrapper width="100px">
          <CopyToClipboardText
            value={segment.flightNumber}
            sx={{
              maxWidth: "inherit",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              display: "inline-block",
            }}
          />
        </TextWrapper>
      </TableCell>
      <TableCell>
        <TextWrapper width="150px">
          <CopyToClipboardText
            value={segment.eTicket}
            sx={{
              maxWidth: "inherit",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              display: "inline-block",
            }}
          />
        </TextWrapper>
      </TableCell>
      <TableCell align="right">{segment.classCode || ""}</TableCell>
      <TableCell>
        <TextWrapper>
          <TripLink trip={segment.trip} id={segment.tripId} />
        </TextWrapper>
      </TableCell>
      <GenericLoyaltyProgramActivityColumn
        loyaltyProgramActivity={primaryLoyaltyProgram}
        showOnlySource={showOnlySource}
      />
    </TableRow>
  );
}
