import Joi from "joi";
import { BOOKED, CANCELLED, REFUNDED, SERVICE, STAY, TRAVEL } from "./BookingConstants.js";
import StayValidator from "./StayValidator.js";
import SegmentValidator from "./SegmentValidator.js";
import BookingPaymentValidator from "./BookingPaymentValidator.js";

const BookingValidator = Joi.object({
  bookingReference: Joi.string().optional().empty(""),
  id: Joi.string().required(),
  title: Joi.string().required(),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
  bookingDate: Joi.date().optional(),
  startDate: Joi.date().optional(),
  endDate: Joi.date().optional(),
  type: Joi.array()
    .items(Joi.string().required().allow(TRAVEL, SERVICE, STAY))
    .required(),
  status: Joi.string().required().allow(BOOKED, REFUNDED, CANCELLED),
  trip: Joi.object({
    id: Joi.string().required(),
    title: Joi.string().required(),
  }).optional(),
  isReturn: Joi.boolean().optional(),
  isSkipped: Joi.boolean().optional(),
  isConnectedTravelSequence: Joi.boolean().optional(),
  priceCurrency: Joi.string().empty(""),
  pointsPaid: Joi.number().min(0).optional().precision(0),
  pricePaid: Joi.number().min(0).optional().precision(2),
  price: Joi.number().min(0).optional().precision(2),
  pricePerNight: Joi.number().min(0).optional().precision(2),
  canBeCancelled: Joi.boolean().optional(),
  cancelBeforeDate: Joi.date().optional(),
  note: Joi.string().optional(),
  isPaid: Joi.boolean().optional(),
  isPriceEstimated: Joi.boolean().optional(),
  _nights: Joi.number().optional().min(0),
  pricePaidPerNight: Joi.number().min(0).optional().precision(2),
  leadingCarrier: Joi.object({
    id: Joi.string().required(),
    title: Joi.string().required(),
    type: Joi.string().optional(),
  }).optional(),
  leadingStayBrand: Joi.object({
    id: Joi.string().required(),
    title: Joi.string().required(),
  }).optional(),
  leadingStayLocation: Joi.object({
    id: Joi.string().required(),
    title: Joi.string().required(),
    timeZone: Joi.string().optional(),
  }).optional(),
  agent: Joi.object({
    id: Joi.string().required(),
    title: Joi.string().required(),
  }).optional(),
  paymentMethod: Joi.object({
    id: Joi.string().required(),
  }).optional(),
  segments: Joi.array().items(SegmentValidator).optional(),
  stays: Joi.array().items(StayValidator).optional(),
  payments: Joi.array().items(BookingPaymentValidator).optional(),
  segmentCarrierIds: Joi.array().items(Joi.string()).required(),
  segmentStationIds: Joi.array().items(Joi.string()).required(),
}).label("Booking");

export default BookingValidator;
