import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import React from "react";
import { BOOKED } from "../../models/BookingConstants.js";
import GenericStaysRow from "./GenericStaysRow.js";

const startState = () => ({ count: 0 });

export default function GenericStaysTable({
  stays,
  linkState,
  isLoading,
  showOnlySource,
  showBookingDate,
  loyaltyProgramId,
}) {
  const stats = stays
    ? stays.reduce((acc, current) => {
        if (current.booking.status !== BOOKED || current.isSkipped) {
          return acc;
        }

        acc.count += 1;

        return acc;
      }, startState())
    : startState();

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            {showBookingDate && <TableCell>Booking Date</TableCell>}
            <TableCell>
              <TableSortLabel active direction="desc" disabled>
                Check-in
              </TableSortLabel>
            </TableCell>
            <TableCell>Check-out</TableCell>
            <TableCell>Nights</TableCell>
            <TableCell>Brand</TableCell>

            <TableCell>{isLoading ? "Location" : `Location (${stats.count})`}</TableCell>
            <TableCell>Reference</TableCell>
            <TableCell>Room No.</TableCell>
            <TableCell>Trip</TableCell>
            <TableCell colSpan={3}>Loyalty</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading === false && stays && stays.length === 0 && (
            <TableRow>
              <TableCell colSpan={showBookingDate ? 12 : 11}>No results</TableCell>
            </TableRow>
          )}
          {stays?.map((stay) => {
            return (
              <GenericStaysRow
                key={stay.id}
                stay={stay}
                linkState={linkState}
                showOnlySource={showOnlySource}
                showBookingDate={showBookingDate}
                loyaltyProgramId={loyaltyProgramId}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
