// https://stackoverflow.com/questions/75242893/javascript-intl-how-to-get-all-timezones-with-abbrivation-and-offset
// https://stackoverflow.com/questions/76647985/how-to-get-all-long-time-zone-names-like-europe-berlin

export default function getTimeZones(locales = ["en"]) {
  return Intl.supportedValuesOf("timeZone").map((timeZone) => {
    const offset = new Intl.DateTimeFormat(locales, { timeZone: timeZone, timeZoneName: "shortOffset" })
      .formatToParts()
      .find((part) => part.type === "timeZoneName").value;
    const timeZoneAbbreviation = new Intl.DateTimeFormat(locales, { timeZone: timeZone, timeZoneName: "long" })
      .formatToParts()
      .find((part) => part.type === "timeZoneName").value;

    return {
      timeZone, // "Australia/Sydney"
      timeZoneAbbreviation, // "Australian Eastern Daylight Time"
      offset, // "GMT+11"
    };
  });
}
