import Joi from "joi";

const StayValidator = Joi.object({
  id: Joi.string().optional(),
  trip: Joi.object({
    id: Joi.string().optional(),
    title: Joi.string().optional(),
  }).optional(),
  booking: Joi.object({
    id: Joi.string().optional(),
    status: Joi.string().optional(),
    bookingReference: Joi.string().optional().empty(""),
    bookingDate: Joi.date().optional(),
  }).optional(),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
  checkInDate: Joi.date().optional(),
  nights: Joi.number().integer().positive().optional(),
  isSkipped: Joi.boolean().optional(),
  isBreakfastIncluded: Joi.boolean().optional(),
  hasFitnessCenter: Joi.boolean().optional(),
  checkOutDate: Joi.date().optional(),
  brand: Joi.object({
    id: Joi.string().required(),
    title: Joi.string().required(),
  }).required(),
  location: Joi.object({
    id: Joi.string().required(),
    title: Joi.string().required(),
    timeZone: Joi.string().optional(),
  }).required(),
  roomNumber: Joi.string().optional(),
  roomName: Joi.string().optional(),
  note: Joi.string().optional(),
  bookingReference: Joi.string().optional(),
  loyaltyProgramIds: Joi.array().items(Joi.string()).required(),
  loyaltyProgramActivities: Joi.array().items(Joi.object()).required(),
}).label("Stay");

export default StayValidator;
