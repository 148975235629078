import Typography from "@mui/material/Typography";
import React from "react";
import { CANCELLED, REFUNDED } from "../../models/BookingConstants.js";

export default function StatusLabel({ booking, inline }) {
  const sx = inline ? { paddingLeft: 1 } : { paddingRight: 1 };

  if (booking.status === CANCELLED) {
    if (booking.isPaid) {
      return (
        <Typography component="span" variant="body2" color="error.main" sx={sx}>
          Cancelled / Not Refunded
        </Typography>
      );
    }
    return (
      <Typography component="span" variant="body2" color="info.main" sx={sx}>
        Cancelled
      </Typography>
    );
  }
  if (booking.status === REFUNDED) {
    return (
      <Typography component="span" variant="body2" color="info.main" sx={sx}>
        Refunded
      </Typography>
    );
  }
  return null;
}
