import Button from "@mui/material/Button";
import React, { useState } from "react";
import TimeZonePickerModal from "../TimeZone/TimeZonePickerModal.js";

export default function SetTimeZoneButton({ value, onChange }) {
  const [isOpen, setIsOpen] = useState(false);

  const onTimeZoneChangeClick = () => {
    setIsOpen(true);
  };

  const onClose = (newValue) => {
    setIsOpen(false);
    if (newValue) {
      onChange(newValue);
    }
  };

  return (
    <>
      <Button
        color="secondary"
        onClick={onTimeZoneChangeClick}
        sx={{ textTransform: "none", minWidth: "auto" }}
        disableRipple
        disableFocusRipple
      >
        {value || "Not set"}
      </Button>
      <TimeZonePickerModal isOpen={isOpen} onClose={onClose} value={value} key={value} />
    </>
  );
}
