import Joi from "joi";
import {
  UPGRADE_CLASS,
  SEAT_SELECTION,
  PRIORITY_BOARDING,
  MEAL,
  BREAKFAST,
  CHECKED_BAGGAGE,
  LATE_CHECKOUT,
  EARLY_CHECKIN,
  AIRPORT_TRANSFER,
} from "./BookingPaymentConstants.js";

const BookingPaymentValidator = Joi.object({
  id: Joi.string().required(),
  createdAt: Joi.date().required(),
  updatedAt: Joi.date().required(),
  paymentDate: Joi.date().optional(),
  paymentMethod: Joi.object({
    id: Joi.string().required(),
  }).optional(),
  pointsPaid: Joi.number().min(0).optional().precision(0),
  pricePaid: Joi.number().min(0).optional().precision(2),
  price: Joi.number().min(0).optional().precision(2),
  priceCurrency: Joi.string().empty(""),
  title: Joi.string().optional(),
  description: Joi.string().optional(),
  loyaltyProgram: Joi.object({
    title: Joi.string().required(),
    id: Joi.string().required(),
  }).optional(),
  isPriceEstimated: Joi.boolean().optional(),
  isPaid: Joi.boolean().optional(),
  isPrinciple: Joi.boolean().optional(),
  features: Joi.array()
    .items(
      Joi.string().valid(
        UPGRADE_CLASS,
        SEAT_SELECTION,
        PRIORITY_BOARDING,
        MEAL,
        BREAKFAST,
        CHECKED_BAGGAGE,
        LATE_CHECKOUT,
        EARLY_CHECKIN,
        AIRPORT_TRANSFER,
      ),
    )
    .optional(),
}).label("Booking Payment");

export default BookingPaymentValidator;
