import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { useIntl } from "react-intl";
import { Link as ReactRouterLink } from "react-router-dom";
import useSnackBar from "../../contexts/SnackBar/useSnackBar.js";
import stripWhiteSpace from "../../lib/stripWhiteSpace.js";
import { db } from "../../models/db.js";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import RefreshIcon from "@mui/icons-material/Refresh";
import { latitudeValidator, longitudeValidator } from "../BookingEditor/validators.js";
import SetTimeZoneButton from "../StayBrandsLocationsList/SetTimeZoneButton.js";

// const demo = {
//   icao: "SBGR",
//   iata: "GRU",
//   shortName: "Guarulhos - Governador André Franco Montoro",
//   fullName: "São Paulo Guarulhos - Governador André Franco Montoro",
//   municipalityName: "São Paulo",
//   location: {
//     lat: -23.435556,
//     lon: -46.473057,
//   },
//   elevation: {
//     meter: 749.5,
//     km: 0.75,
//     mile: 0.47,
//     nm: 0.4,
//     feet: 2459,
//   },
//   country: {
//     code: "BR",
//     name: "Brazil",
//   },
//   continent: {
//     code: "SA",
//     name: "South America",
//   },
//   timeZone: "America/Sao_Paulo",
//   urls: {
//     webSite: "https://www.gru.com.br/",
//     wikipedia: "https://en.wikipedia.org/wiki/S%C3%A3o_Paulo-Guarulhos_International_Airport",
//     twitter: "http://twitter.com/gruairportsp",
//     googleMaps: "https://www.google.com/maps/@-23.435556,-46.473056,14z",
//     flightRadar: "https://www.flightradar24.com/-23.44,-46.47/14",
//   },
// };
async function getData(iataCode) {
  // return { ...demo, iata: iataCode, icao: `X${iataCode}` };
  let response;

  try {
    response = await fetch(`/api/airports/${iataCode}`);
  } catch (ex) {
    console.log(ex);
    return undefined;
  }

  if (!response) return undefined;

  let body;
  try {
    body = await response.json();
  } catch (ex) {
    console.log(ex);
    return undefined;
  }

  if (body?.message) {
    console.log(body.message);
    return undefined;
  }

  return body || undefined;
}

function getInitialValue(value) {
  if (value === undefined) {
    return {
      valueAsString: "",
      valid: true,
      value: undefined,
    };
  }
  return {
    valueAsString: `${value}`,
    valid: true,
    value: value,
  };
}
export default function StationsTableRow({ station }) {
  const { enqueueSnackbar } = useSnackBar();
  const { formatNumber } = useIntl();

  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState(station.title);
  const [id, setId] = useState(station.id);
  const [icao, setICAO] = useState(station.icao || "");
  const [lat, setLat] = useState(getInitialValue(station.lat));
  const [lon, setLon] = useState(getInitialValue(station.lon));
  const [timeZone, setTimeZone] = useState(station.timeZone || null);

  const onEditClick = () => {
    setEditMode(!editMode);
  };

  const onDeleteClick = async () => {
    const result = await db.deleteStation(station.id);
    if (result?.success) {
      enqueueSnackbar({ message: `${title} Deleted`, variant: "success" });
    } else {
      enqueueSnackbar({ message: result.message, variant: "error" });
    }
  };

  const onCancelClick = () => {
    setEditMode(false);
    setTitle(station.title);
    setId(station.id);
  };

  const onSaveClick = async (extra) => {
    await db.updateStation(
      {
        ...station,
        id,
        title,
        icao,
        lon: lon.value,
        lat: lat.value,
        ...(extra || {}),
      },
      station,
    );
    enqueueSnackbar({ message: `${title} Updated`, variant: "success" });
    setEditMode(false);
  };

  const onDownloadClick = async () => {
    let response = await getData(id);

    if (!response) return;

    const latValidator = latitudeValidator(`${response?.location?.lat ?? ""}`);

    setLat({
      valueAsString: latValidator.valueAsString,
      value: latValidator.value,
      valid: latValidator.error === undefined,
    });

    const lonValidator = longitudeValidator(`${response?.location?.lon ?? ""}`);

    setLon({
      valueAsString: lonValidator.valueAsString,
      value: lonValidator.value,
      valid: lonValidator.error === undefined,
    });

    setICAO(response.icao);

    const extra = {
      timeZone: response.timeZone,
      shortName: response.shortName,
      fullName: response.fullName,
      municipalityName: response.municipalityName,
      country: response.country,
      continent: response.continent,
      hasData: true,
      icao: response.icao,
      lon: lonValidator.value,
      lat: latValidator.value,
    };

    await onSaveClick(extra);
  };

  const onTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const onIdChange = (event) => {
    setId(stripWhiteSpace(event.target.value.toUpperCase()));
  };

  const onICAOChange = (event) => {
    setICAO(stripWhiteSpace(event.target.value.toUpperCase()));
  };

  const onLatChange = (event) => {
    if (!event.target.value.length) {
      setLat({
        valueAsString: event.target.value,
        value: undefined,
        valid: true,
      });
      return;
    }
    const result = latitudeValidator(event.target.value);

    setLat({
      valueAsString: result.valueAsString,
      value: result.value,
      valid: result.error === undefined,
    });
  };

  const onLonChange = (event) => {
    if (!event.target.value.length) {
      setLon({
        valueAsString: event.target.value,
        value: undefined,
        valid: true,
      });
      return;
    }
    const result = longitudeValidator(event.target.value);

    setLon({
      valueAsString: result.valueAsString,
      value: result.value,
      valid: result.error === undefined,
    });
  };

  const onTimeZoneChangeClick = async (value) => {
    setTimeZone(value);
    if (value && !editMode && value !== timeZone) {
      // apply the change immediately
      await db.updateStation(
        {
          id,
          timeZone: value,
        },
        station,
      );
      enqueueSnackbar({ message: `${title} timezone changed`, variant: "success" });
    }
  };

  return (
    <TableRow hover>
      {!editMode && (
        <TableCell>
          <Link to={`/stations/${station.id}`} component={ReactRouterLink}>
            {station.title}
          </Link>
        </TableCell>
      )}
      {editMode && (
        <TableCell>
          <TextField value={title} fullWidth margin="none" required onChange={onTitleChange} />
        </TableCell>
      )}

      {!editMode && <TableCell>{station.id}</TableCell>}
      {editMode && (
        <TableCell>
          <TextField label="IATA" value={id} fullWidth margin="none" required onChange={onIdChange} />
        </TableCell>
      )}
      {!editMode && <TableCell>{station.icao}</TableCell>}

      {editMode && (
        <TableCell>
          <TextField label="ICAO" value={icao} fullWidth margin="none" onChange={onICAOChange} />
        </TableCell>
      )}

      {!editMode && <TableCell>{`${station.lat || "-"} / ${station.lon || "-"}`}</TableCell>}

      {editMode && (
        <TableCell>
          <Stack direction="row" spacing={4}>
            <TextField
              label="Latitude"
              value={lat.valueAsString}
              error={!lat.valid}
              fullWidth
              margin="none"
              onChange={onLatChange}
            />
            <TextField
              label="Longitude"
              value={lon.valueAsString}
              error={!lon.valid}
              fullWidth
              margin="none"
              onChange={onLonChange}
            />
          </Stack>
        </TableCell>
      )}

      <TableCell>
        <SetTimeZoneButton onChange={onTimeZoneChangeClick} value={timeZone} />
      </TableCell>

      {/*<TableCell align="right">*/}
      {/*  <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>*/}
      {/*    {station.lifetimeSpend !== undefined ? (*/}
      {/*      <span>{formatNumber(station.lifetimeSpend, { style: "currency", currency: "GBP" })}</span>*/}
      {/*    ) : (*/}
      {/*      <span>Unknown</span>*/}
      {/*    )}*/}

      {/*  </Stack>*/}
      {/*</TableCell>*/}

      <TableCell align="right">
        <IconButton size="small" disabled={editMode || station.hasData} color="secondary" onClick={onDownloadClick}>
          <RefreshIcon fontSize="small" />
        </IconButton>

        {editMode && (
          <Button color="secondary" onClick={onCancelClick}>
            Cancel
          </Button>
        )}
        {!editMode && (
          <Button color="secondary" disabled onClick={onDeleteClick}>
            Delete
          </Button>
        )}
        {!editMode && (
          <Button color="secondary" onClick={onEditClick}>
            Edit
          </Button>
        )}

        {editMode && (
          <Button color="primary" onClick={onSaveClick}>
            Save
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
}
