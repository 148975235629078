import React from "react";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../models/db.js";
import GenericBookingsTable from "../GenericBookingsTable/GenericBookingsTable.js";

export default function StayBrandsBookingsList({ stayBrandId }) {
  const bookings = useLiveQuery(() =>
    db.bookings.where("leadingStayBrand.id").equals(stayBrandId).reverse().sortBy("bookingDate"),
  );

  return (
    <GenericBookingsTable bookings={bookings} showTrip isLoading={bookings === undefined} showPerNight showRoomNumber />
  );
}
