import { createTheme } from "@mui/material/styles";
import cssBaseline from "./cssBaseline.js";
import { alertClasses } from "@mui/material/Alert";
import { alpha } from "@mui/material/styles";
const palette = {
  mode: "dark",
  primary: {
    // main: "#E7301C",
    main: "#FFC700",
  },
  secondary: {
    main: "#C9D4C5",
  },
  background: {
    default: "#000000",
    paper: "#000000",
  },
};

const fontFamily = [
  "Switzer",
  "SF Pro Text",
  "SF Pro Icons",
  "Helvetica Neue",
  "Helvetica",
  "-apple-system",
  "system-ui",
  "Arial",
  "sans-serif",
].join(",");

const themeConfig = {
  palette,
  typography: {
    fontFamily,
    h1: {
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "22px",
    },
    h2: {
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "22px",
    },
    h3: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "17px",
    },
    fontSize: 13,
  },
  mixins: {
    toolbar: {
      minHeight: 60,
      marginBottom: 1,
    },
    totalToolbarHeight: 61,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: cssBaseline,
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
        autoComplete: "off",
        margin: "dense",
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: theme.typography.body2.fontSize,
          // color: "red",
        }),
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: ({ theme }) => ({
          fontSize: theme.typography.body2.fontSize,
          // color: "red",
        }),
      },
    },
    MuiButton: {
      defaultProps: {
        size: "small",
      },
      // styleOverrides: {
      //   root: ({ theme }) => ({
      //     fontSize: theme.typography.body2.fontSize,
      //   }),
      // },
    },
    MuiSvgIcon: {
      defaultProps: {
        fontSize: "small",
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          "& button, & a, & .MuiButton-root": {
            appRegion: "no-drag",
            WebkitAppRegion: "no-drag",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 60,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "6px 12px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          userSelect: "none",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          [`&.${alertClasses.filled}.${alertClasses.colorSuccess}`]: {
            color: "white",
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.secondary.main,
          borderColor: alpha(theme.palette.secondary.main, 0.5),
        }),
      },
    },
  },
};

const theme = createTheme(themeConfig);

export default theme;
