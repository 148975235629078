import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import React, { Fragment } from "react";
import { useIntl } from "react-intl";
import { Link as ReactRouterLink } from "react-router-dom";
import { TRAVEL } from "../../models/BookingConstants.js";
import { FLIGHT } from "../../models/CarrierConstants.js";
import getSegmentFullTitle from "../../models/getSegmentFullTitle.js";
import getStayFullTitle from "../../models/getStayFullTitle.js";
import CopyToClipboardText from "../CopyToClipboardText.js";
import { CancelInformationText } from "../GenericBookingsTable/BookingDescriptionText.js";
import BookingTypeIcon from "./BookingTypeIcon.js";
import TableSortLabel from "@mui/material/TableSortLabel";
import TransitEnterexitIcon from "@mui/icons-material/TransitEnterexit";

function ItemTitleTableCell({ item, booking, colSpan, type, linkState }) {
  const { formatNumber } = useIntl();

  const hasNote = booking?.note?.length > 0;

  const note = hasNote ? (
    <Typography variant="body2" gutterBottom sx={{ marginTop: 0.5 }}>
      {booking.note}
    </Typography>
  ) : null;
  if (type === TRAVEL) {
    const parts = [];

    if (item.flightNumber) {
      parts.push(item.flightNumber);
    }

    if (item.isOvernight) {
      parts.push("Overnight");
    }

    if (item.isSecondaryPassenger) {
      parts.push("Secondary Passenger");
    }

    return (
      <TableCell colSpan={colSpan}>
        <div>
          <Link to={`/bookings/${booking.id}`} component={ReactRouterLink} state={linkState}>
            {getSegmentFullTitle({
              segment: item,
              showStationIds: item.carrier.type === FLIGHT || booking.leadingCarrier?.type === FLIGHT,
            })}
          </Link>
          {parts.length > 0 ? ` - ${parts.join(", ")}` : ""}
        </div>

        {note}
      </TableCell>
    );
  }

  const parts = [];

  if (item.nights === 1) {
    parts.push(`${item.nights} night`);
  } else if (item.nights) {
    parts.push(`${item.nights} nights`);
  }

  if (item.isBreakfastIncluded === true) {
    parts.push(`breakfast included`);
  } else if (item.isBreakfastIncluded === false) {
    parts.push(`without breakfast`);
  }

  if (item.hasFitnessCenter === true) {
    parts.push(`fitness center`);
  } else if (item.hasFitnessCenter === false) {
    parts.push(`no fitness center`);
  }

  if (booking.pricePaid) {
    parts.push(`${formatNumber(booking.pricePaid, { style: "currency", currency: "GBP" })}`);
  }

  let perNightConst =
    booking.pricePaidPerNight > 0 && item.nights > 1
      ? `~${formatNumber(booking.pricePaidPerNight, { style: "currency", currency: "GBP", maximumFractionDigits: 0 })} per night`
      : "";
  if (perNightConst?.length > 0) {
    parts.push(perNightConst);
  }

  return (
    <TableCell colSpan={colSpan}>
      <Link to={`/bookings/${booking.id}`} component={ReactRouterLink} state={linkState}>
        {getStayFullTitle(item)}
      </Link>
      {parts.length > 0 ? ` - ${parts.join(", ")}` : ""}
      <Typography component="div" variant="body2" color="secondary">
        <CancelInformationText booking={booking} />
        {note}
      </Typography>
    </TableCell>
  );
}

export default function ItemsByDateTable({ bookingsOrderedByDate }) {
  const { formatDate, formatNumber } = useIntl();
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>

            <TableCell>
              <TableSortLabel active direction="asc" disabled>
                Start
              </TableSortLabel>
            </TableCell>
            <TableCell>End</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Reference</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bookingsOrderedByDate.map(({ item, booking, startDate, endDate, type, nights }) => {
            const startDateText = formatDate(startDate, {
              year: "numeric",
              month: "short",
              day: "numeric",
              weekday: "short",
            });

            const endDateText = endDate
              ? formatDate(endDate, {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  weekday: "short",
                })
              : undefined;

            const row = (
              <TableRow key={item.id} hover>
                <TableCell>
                  <BookingTypeIcon booking={booking} type={type} />
                </TableCell>
                <TableCell>{startDateText}</TableCell>
                <TableCell>{endDateText && endDateText !== startDateText ? endDateText : ""}</TableCell>
                <ItemTitleTableCell
                  item={item}
                  booking={booking}
                  type={type}
                  linkState={{ source: "trip.itinerary" }}
                />

                <TableCell>{item.roomNumber || ""}</TableCell>

                <TableCell>
                  <CopyToClipboardText enablePrivacy value={item.carrierReference || booking.bookingReference} />
                </TableCell>
              </TableRow>
            );

            if (nights > 0) {
              return (
                <Fragment key={`${item.id}_group`}>
                  <TableRow key={`${item.id}_night`} hover>
                    <TableCell>
                      <TransitEnterexitIcon fontSize="small" color="secondary" />
                    </TableCell>
                    <TableCell colSpan={5}>
                      <Typography
                        variant="body2"
                        fontStyle="italic"
                        color="textSecondary"
                      >{`${formatNumber(nights)} nights gap`}</Typography>
                    </TableCell>
                  </TableRow>
                  {row}
                </Fragment>
              );
            }
            return row;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
