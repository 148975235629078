import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import React from "react";
import { ToggleButtonCompact, ToggleButtonIcon } from "../controls/ToggleButton.js";
import FixedFooterAppBar from "../FixedFooterAppBar.js";
import MainScrollLayout from "../MainScrollLayout.js";
import StayBrandsBookingsList from "../StayBrandsBookingsList/StayBrandsBookingsList.js";
import { StayBrandsDetailsCardQuery } from "../StayBrandsBookingsList/StayBrandsDetailsCard.js";
import StayBrandsLocationList from "../StayBrandsLocationsList/StayBrandsLocationList.js";
import SubScrollLayout from "../SubScrollLayout.js";
import { Link as ReactRouterLink, useParams } from "react-router-dom";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import Map from "@mui/icons-material/Map";

export default function StayBrandsDetailsPage() {
  const { stayBrandId, tab } = useParams();

  const tabSafe = tab || "bookings";

  return (
    <>
      <MainScrollLayout footerBar fixed flexColumn>
        <StayBrandsDetailsCardQuery stayBrandId={stayBrandId} />
        <Divider />
        <SubScrollLayout key={tabSafe}>
          {tabSafe === "bookings" && <StayBrandsBookingsList stayBrandId={stayBrandId} />}
          {tabSafe === "locations" && <StayBrandsLocationList stayBrandId={stayBrandId} />}
        </SubScrollLayout>
      </MainScrollLayout>
      <FixedFooterAppBar>
        <ToggleButtonGroup exclusive value={tabSafe}>
          <ToggleButtonCompact
            color="primary"
            size="small"
            value="bookings"
            component={ReactRouterLink}
            to={`/hotels/${stayBrandId}`}
          >
            <ToggleButtonIcon>
              <ConfirmationNumberIcon />
            </ToggleButtonIcon>
            Bookings
          </ToggleButtonCompact>
          <ToggleButtonCompact
            color="primary"
            size="small"
            value="locations"
            component={ReactRouterLink}
            to={`/hotels/${stayBrandId}/locations`}
          >
            <ToggleButtonIcon>
              <Map />
            </ToggleButtonIcon>
            Locations
          </ToggleButtonCompact>
        </ToggleButtonGroup>
      </FixedFooterAppBar>
    </>
  );
}
