import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid2";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import React, { useReducer } from "react";
import { useIntl } from "react-intl";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import useCurrency from "../../contexts/Currency/useCurrency.js";
import useSnackBar from "../../contexts/SnackBar/useSnackBar.js";
import { db } from "../../models/db.js";
import { isValid as isValidDate, isDate, startOfHour } from "date-fns";
import FixedFooterAppBar from "../FixedFooterAppBar.js";
import MainScrollLayout from "../MainScrollLayout.js";
import SubScrollLayout from "../SubScrollLayout.js";
import AgentsAutocomplete from "./AgentsAutocomplete.js";
import BookingEditorInitialState from "./BookingEditorInitialState.js";
import BookingEditorReducer from "./BookingEditorReducer.js";
import BookingPayments from "./BookingPayments.js";
import BookingSegments from "./BookingSegments.js";
import BookingStatusField from "./BookingStatusField";
import BookingStays from "./BookingStays.js";
import cleanDateValue from "./cleanDateValue.js";
import CurrencySelectField from "./CurrencySelectField";
import DeleteBookingButton from "./DeleteBookingButton.js";
import generateData from "./generateData.js";
import saveData from "./saveData.js";
import TripSelectField from "./TripSelectField.js";

export default function BookingEditor({
  existingData,
  isEditing: isEditingPage,
  type,
  trip,
  bookings,
  agent,
  carrier,
  source,
}) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackBar();
  const { formatNumber } = useIntl();
  const { convertCurrency } = useCurrency();

  const [state, dispatch] = useReducer(
    BookingEditorReducer,
    { existingData, isEditing: isEditingPage, type, trip, bookings, agent, carrier, source },
    BookingEditorInitialState,
  );

  const setState = (value) => dispatch({ type: "SET_STATE", value });

  const setSegment = (id, value, type) => dispatch({ type: type || "SET_SEGMENT", id, value });

  const setStay = (id, value, type) => dispatch({ type: type || "SET_STAY", id, value });

  const setBookingPayment = (id, value, type) => dispatch({ type: type || "SET_PAYMENT", id, value });

  const onStatusChange = (event) => setState({ status: event.target.value });

  const onNoteChange = (event) => setState({ note: event.target.value });

  const onAgentChange = (agent) => setState({ agent });

  const onTripChange = (trip) => {
    dispatch({ type: "SET_TRIP", value: trip });
  };

  const performConversionIfNeeded = (update, priceCurrency) => {
    const compareData = update || {
      priceAsString: state.priceAsString,
      price: state.price,
      priceIsValid: state.priceIsValid,
    };

    if (priceCurrency === "GBP") {
      // no currency to change
      return {
        pricePaidAsString: compareData.priceAsString,
        pricePaid: compareData.price,
        pricePaidIsValid: compareData.priceIsValid,
      };
    }
    if (!compareData.priceIsValid || !state.isPriceEstimated || !compareData.priceAsString.length) {
      return {};
    }

    const result = convertCurrency(compareData.priceAsString, priceCurrency, "GBP");
    if (result === undefined) {
      return {};
    }

    const pricePaidAsString = formatNumber(result, {
      useGrouping: false,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return {
      pricePaidAsString: pricePaidAsString,
      pricePaid: result,
      pricePaidIsValid: true,
    };
  };

  const onCurrencyChange = (event) => {
    const nextUpdate = {
      priceCurrency: event.target.value,
      ...performConversionIfNeeded(undefined, event.target.value),
    };

    setState(nextUpdate);
  };

  const editDate = (newValue) => {
    const next = cleanDateValue(newValue);
    setState({ bookingDate: cleanDateValue(next), suggestedCheckInDate: next, suggestedDepartureDate: next });
  };

  const editCancelBeforeDate = (newValue) => {
    const isNewValueValidDate = newValue && isDate(newValue) && isValidDate(newValue);

    setState({ cancelBeforeDate: isNewValueValidDate ? startOfHour(newValue) : newValue });
  };

  const { bookingDate, bookingReference, isValid, isEditing, createdAt, cancelBeforeDate } = state;

  const onSaveClick = async () => {
    const data = generateData(state);
    if (!data) return;

    let title = "Booking Created";

    if (existingData?.booking) {
      title = "Booking Updated";
    }
    const saves = await saveData(data, existingData);

    if (saves.stayBrandLifeTimeSpend > 0) {
      enqueueSnackbar({
        message: `${data.booking.leadingStayBrand.title} lifetime spend is ${formatNumber(saves.stayBrandLifeTimeSpend, { style: "currency", currency: "GBP" })}`,
        title,
        variant: "success",
      });
    } else if (saves.leadingCarrierLifeTimeSpend > 0) {
      enqueueSnackbar({
        message: `${data.booking.leadingCarrier.title} lifetime spend is ${formatNumber(saves.leadingCarrierLifeTimeSpend, { style: "currency", currency: "GBP" })}`,
        title,
        variant: "success",
      });
    } else {
      enqueueSnackbar({ message: title, variant: "success" });
    }

    if (source === "flights") {
      navigate(`/flights`);
      return;
    }
    if (data.booking.trip) {
      navigate(state.cancelPath);
      // navigate(`/trips/${data.booking.trip.id}`);
    } else if (source === "agent" && data.booking.agent) {
      navigate(`/agents/${data.booking.agent.id}`);
    } else if (source === "carrier" && data.booking.leadingCarrier) {
      navigate(`/carriers/${data.booking.leadingCarrier.id}`);
    } else {
      navigate(`/bookings/${data.booking.id}`);
    }
  };

  const onAddTravelClick = () => {
    dispatch({ type: "ADD_SEGMENT" });
  };

  const onAddStayClick = () => {
    dispatch({ type: "ADD_STAY" });
  };

  const onAddPaymentClick = () => {
    dispatch({ type: "ADD_PAYMENT" });
  };

  const onDeleteClick = async () => {
    await db.deleteBooking(state.id);
    navigate(state.cancelPath);
    enqueueSnackbar({ title: "Booking Deleted", message: existingData.booking.title, variant: "success" });
  };

  const onViewStateClick = async () => {
    console.log(state);
    generateData(state);
  };

  return (
    <>
      <MainScrollLayout footerBar fixed flexColumn>
        <Card square sx={{ flexShrink: 0 }}>
          <CardContent>
            <Grid container spacing={1} rowSpacing={2}>
              <Grid size="auto">
                <DatePicker
                  disableToolbar
                  sx={{ maxWidth: 150, width: 150 }}
                  variant="inline"
                  inputVariant="outlined"
                  autoOk
                  label="Booking Date"
                  value={bookingDate}
                  maxDate={state.bookingDateMaxValue}
                  minDate={state.bookingDateMinValue}
                  onChange={editDate}
                  timezone="UTC"
                  referenceDate={createdAt}
                  showDaysOutsideCurrentMonth
                  format="dd/MM/yy"
                  slotProps={{
                    openPickerButton: { size: "small" },
                    openPickerIcon: { fontSize: "small" },
                    textField: {
                      variant: "outlined",
                      label: "Booking Date",
                      margin: "none",
                      fullWidth: true,
                      required: true,
                    },
                  }}
                />
              </Grid>

              <Grid size="auto">
                <DateTimePicker
                  disableToolbar
                  sx={{ maxWidth: 190, width: 190 }}
                  variant="inline"
                  inputVariant="outlined"
                  autoOk
                  label="Cancel Before"
                  value={cancelBeforeDate}
                  maxDate={state.endDateMaxValue}
                  minDate={state.bookingDateMinValue}
                  onChange={editCancelBeforeDate}
                  timezone="UTC"
                  referenceDate={createdAt}
                  minutesStep={60}
                  showDaysOutsideCurrentMonth
                  disableHighlightToday
                  views={["year", "day", "hours"]}
                  format="dd/MM/yy h:mm a"
                  slotProps={{
                    openPickerButton: { size: "small" },
                    openPickerIcon: { fontSize: "small" },
                    textField: {
                      variant: "outlined",
                      label: "Cancel Before",
                      margin: "none",
                      fullWidth: true,
                      required: false,
                      placeholder: "",
                    },
                  }}
                />
              </Grid>

              <Grid size={2}>
                <TextField
                  label="Booking Reference"
                  value={bookingReference}
                  onChange={(event) => {
                    setState({ bookingReference: event.target.value });
                  }}
                  fullWidth
                  margin="none"
                />
              </Grid>

              <Grid size={2}>
                <BookingStatusField onChange={onStatusChange} value={state.status} />
              </Grid>

              <Grid size={2}>
                <AgentsAutocomplete
                  onChange={onAgentChange}
                  value={state.agent}
                  disabled={state.canChangeAgent === false}
                />
              </Grid>

              <Grid size={"grow"}>
                <TripSelectField
                  value={state.trip}
                  onChange={onTripChange}
                  required={state.tripRequired}
                  disabled={state.disableTripSelection}
                />
              </Grid>

              <Grid size={12}>
                <TextField
                  label="Note"
                  value={state.note}
                  margin="none"
                  onChange={onNoteChange}
                  fullWidth
                  required={state.type === "SERVICE"}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} rowSpacing={2} sx={{ marginTop: 2 }}>
              <Grid size={4}>
                <Stack spacing={1} direction="row">
                  <TextField
                    label="Price Paid (GBP)"
                    value={formatNumber(state.pricePaid, {
                      useGrouping: false,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    margin="none"
                    disabled
                    inputProps={{
                      inputMode: "numeric",
                    }}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <FormControlLabel
                            sx={{ marginRight: 0 }}
                            disabled
                            control={
                              <Checkbox
                                sx={{ padding: 1 }}
                                checked={state.isPriceEstimated && state.priceCurrency !== "GBP"}
                                size="small"
                              />
                            }
                            label="Estimate"
                            slotProps={{
                              typography: {
                                variant: "body2",
                              },
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
              </Grid>

              <Grid size={4}>
                <Stack spacing={1} direction="row">
                  <TextField
                    label="Price"
                    value={formatNumber(state.price, {
                      useGrouping: false,
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    margin="none"
                    disabled
                    inputProps={{
                      inputMode: "numeric",
                    }}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <FormControlLabel
                            sx={{ marginRight: 0 }}
                            disabled
                            control={
                              <Checkbox sx={{ padding: 1 }} color="success" checked={state.isPaid} size="small" />
                            }
                            label="Paid"
                            slotProps={{
                              typography: {
                                variant: "body2",
                              },
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <CurrencySelectField value={state.priceCurrency} onChange={onCurrencyChange} disabled />
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
        </Card>
        <SubScrollLayout>
          <BookingPayments state={state} setBookingPayment={setBookingPayment} />
          <BookingSegments state={state} setSegment={setSegment} />
          <BookingStays state={state} setStay={setStay} />
        </SubScrollLayout>
      </MainScrollLayout>
      <FixedFooterAppBar>
        <Button
          disabled={!isValid}
          startIcon={<SaveIcon fontSize="small" />}
          color="primary"
          variant="contained"
          onClick={onSaveClick}
        >
          {isEditing ? "Update" : "Save"}
        </Button>

        {state.isEditing && (
          <Button
            startIcon={<CloseIcon fontSize="small" />}
            color="secondary"
            variant="outlined"
            component={ReactRouterLink}
            to={state.cancelPath}
          >
            Close
          </Button>
        )}

        {state.isEditing === false && (
          <Button
            startIcon={<CloseIcon fontSize="small" />}
            color="secondary"
            variant="outlined"
            component={ReactRouterLink}
            to={state.cancelPath}
          >
            Cancel
          </Button>
        )}

        {state.tripPath && state.tripPath !== state.cancelPath && (
          <Button
            startIcon={<ModeOfTravelIcon fontSize="small" />}
            color="secondary"
            variant="outlined"
            component={ReactRouterLink}
            to={state.tripPath}
            disabled={!state.tripPath?.length}
          >
            View Trip
          </Button>
        )}

        <Divider orientation="vertical" variant="middle" flexItem />

        {state.canAddSegments && (
          <Button
            disabled={!state.canAddSegments}
            startIcon={<AddIcon fontSize="small" />}
            color="secondary"
            variant="outlined"
            onClick={onAddTravelClick}
          >
            Add Segment
          </Button>
        )}

        {state.canAddStays && (
          <Button
            startIcon={<AddIcon fontSize="small" />}
            color="secondary"
            variant="outlined"
            onClick={onAddStayClick}
            disabled={state.stays.length !== 0}
          >
            Add Stay
          </Button>
        )}

        <Button
          startIcon={<AddIcon fontSize="small" />}
          color="secondary"
          variant="outlined"
          onClick={onAddPaymentClick}
        >
          Add Payment
        </Button>
        <Divider orientation="vertical" variant="middle" flexItem />

        <DeleteBookingButton onClick={onDeleteClick} disabled={!state.isEditing} />

        <Button variant="outlined" color="secondary" onClick={onViewStateClick}>
          View State
        </Button>
      </FixedFooterAppBar>
    </>
  );
}
