import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Radio from "@mui/material/Radio";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React from "react";
import { useIntl } from "react-intl";
import formatETicket from "../../lib/formatETicket.js";
import stripWhiteSpace from "../../lib/stripWhiteSpace.js";
import CarriersAutoComplete from "./CarriersAutocomplete.js";
import EditorRowActionsButton from "./EditorRowActionsButton.js";
import LoyaltyProgramActivityButton from "./LoyaltyProgramActivity/LoyaltyProgramActivityButton.js";
import StationsAutocomplete from "./StationsAutocomplete.js";
import cleanDateValue from "./cleanDateValue.js";
import getSegmentFullTitle from "../../models/getSegmentFullTitle.js";
import ToggleButton from "@mui/material/ToggleButton";
import PlusOneIcon from "@mui/icons-material/PlusOne";
export default function SegmentEditorRow({
  segment,
  setSegment,
  showLeadingCarrier,
  referenceDate,
  endDateMaxValue,
  bookingDateMinValue,
}) {
  const { formatNumber } = useIntl();

  const {
    eTicket,
    carrier,
    id,
    carrierReference,
    origin,
    originTitle,
    destination,
    destinationTitle,
    departureDate,
    createdAt,
    isLeadingCarrier,
    classCode,
    flightNumber,
    isOvernight,
    distanceInKm,
  } = segment;

  const onCarrierSelected = (newValue) => {
    setSegment(id, {
      carrier: newValue,
    });
  };

  const onFromSelected = (newValue, newCustomTitle) => {
    setSegment(id, {
      origin: newValue,
      originTitle: (newValue?.id ? "" : newCustomTitle) || "",
    });
  };

  const onToSelected = (newValue, newCustomTitle) => {
    setSegment(id, {
      destination: newValue,
      destinationTitle: (newValue?.id ? "" : newCustomTitle) || "",
    });
  };

  const onToggleLeadingCarrier = () => {
    setSegment(id, !isLeadingCarrier, "SET_LEADING_CARRIER");
  };

  const onDeleteClick = () => {
    setSegment(id, undefined, "DELETE_SEGMENT");
  };

  const onLoyaltyProgramActivityChange = (value, event) => {
    setSegment(id, value, event);
  };

  return (
    <Card key={id} elevation={1}>
      <CardContent>
        <Stack direction="row" spacing={1}>
          <DatePicker
            disableToolbar
            variant="inline"
            inputVariant="outlined"
            autoOk
            label="Departure"
            value={departureDate}
            onChange={(newValue) => {
              setSegment(id, { departureDate: cleanDateValue(newValue) });
            }}
            sx={{ maxWidth: 100, width: 100 }}
            minDate={bookingDateMinValue}
            maxDate={endDateMaxValue}
            referenceDate={referenceDate || createdAt}
            timezone="UTC"
            showDaysOutsideCurrentMonth
            format="dd/MM/yy"
            slotProps={{
              openPickerButton: { size: "small" },
              openPickerIcon: { fontSize: "small" },
              textField: {
                variant: "standard",
                label: "Departure",
                margin: "none",
              },
            }}
          />
          <TextField
            label="Flight No."
            value={flightNumber}
            onChange={(event) => {
              setSegment(id, { flightNumber: stripWhiteSpace(event.target.value.toUpperCase()) });
            }}
            variant="standard"
            margin="none"
            sx={{ maxWidth: 70 }}
          />
          <CarriersAutoComplete value={carrier} onChange={onCarrierSelected} />
          <StationsAutocomplete
            required
            value={origin}
            valueName={originTitle}
            onChange={onFromSelected}
            label="From"
          />
          <StationsAutocomplete
            required
            value={destination}
            valueName={destinationTitle}
            onChange={onToSelected}
            label="To"
            helperText={
              distanceInKm
                ? `${formatNumber(distanceInKm, {
                    style: "unit",
                    unit: "kilometer",
                    maximumFractionDigits: 0,
                  })}`
                : undefined
            }
          />

          <Box sx={{ alignSelf: "center" }}>
            <ToggleButton
              value="isOvernight"
              selected={isOvernight || false}
              onChange={() => {
                setSegment(id, { isOvernight: !isOvernight });
              }}
              size="small"
              color="success"
              disableTouchRipple
            >
              <PlusOneIcon />
            </ToggleButton>
          </Box>
          <TextField
            label="Class"
            value={classCode}
            onChange={(event) => {
              setSegment(id, { classCode: stripWhiteSpace(event.target.value.toUpperCase()) });
            }}
            variant="standard"
            margin="none"
            sx={{ maxWidth: 54 }}
          />

          <TextField
            label="eTicket"
            value={eTicket}
            onChange={(event) => {
              setSegment(id, { eTicket: formatETicket(event.target.value) });
            }}
            inputProps={{
              inputMode: "numeric",
            }}
            variant="standard"
            margin="none"
          />
          <TextField
            label="Carrier Reference"
            value={carrierReference}
            onChange={(event) => {
              setSegment(id, { carrierReference: stripWhiteSpace(event.target.value.toUpperCase()) });
            }}
            inputProps={{
              inputMode: "numeric",
            }}
            variant="standard"
            margin="none"
          />
          <Divider orientation="vertical" variant="middle" flexItem />
          <Box sx={{ alignSelf: "center" }}>
            <Tooltip title="Leading Carrier" arrow placement="bottom-start">
              <span>
                <Radio
                  checked={isLeadingCarrier || false}
                  size="small"
                  onChange={onToggleLeadingCarrier}
                  disabled={!showLeadingCarrier}
                />
              </span>
            </Tooltip>
          </Box>
          <Box sx={{ alignSelf: "center" }}>
            <LoyaltyProgramActivityButton
              segment={segment}
              title={getSegmentFullTitle({ segment, showFlightNumber: true })}
              onChange={onLoyaltyProgramActivityChange}
              loyaltyProgramActivities={segment.loyaltyProgramActivities}
              referenceDate={departureDate}
              minDate={bookingDateMinValue}
            />
          </Box>

          <Box sx={{ alignSelf: "center" }}>
            <EditorRowActionsButton value={segment} set={setSegment} isSegment onDelete={onDeleteClick} />
          </Box>
        </Stack>
      </CardContent>
      <Divider />
    </Card>
  );
}
