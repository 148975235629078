import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import React from "react";
import useSnackBar from "../../contexts/SnackBar/useSnackBar.js";
import useStorageManager from "../../contexts/StorageManager/useStorageManager.js";

export default function PersistDataMenuItem({ onClick }) {
  const { enqueueSnackbar } = useSnackBar();
  const { isPersistent, isChecked, request } = useStorageManager();

  const performCheck = async () => {
    const result = await request();
    if (result) {
      enqueueSnackbar({ message: `Data is now persistent.`, variant: "success" });
    } else {
      enqueueSnackbar({ message: `Persistent storage was not obtained.`, variant: "error" });
    }
  };

  const onClickHandler = async () => {
    onClick();
    await performCheck();
  };

  const selected = isPersistent || false;

  return (
    <MenuItem
      selected={selected}
      onClick={onClickHandler}
      disabled={!isChecked || isPersistent}
      disableRipple
      disableTouchRipple
    >
      <ListItemIcon>
        <Checkbox
          sx={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0 }}
          checked={selected}
          size="small"
          disableRipple
          disabled={!isChecked || isPersistent}
        />
      </ListItemIcon>
      <ListItemText primary="Persist Data" />
    </MenuItem>
  );
}
