import BookingPaymentValidator from "./BookingPaymentValidator.js";

/**
 * Represents a financial payment on a booking, can be for the booking itself, or for charged extras added later.
 */
export default class BookingPayment {
  constructor({
    id,
    createdAt,
    updatedAt,
    paymentDate,
    paymentMethod,
    pointsPaid,
    pricePaid,
    price,
    priceCurrency,
    title,
    description,
    loyaltyProgram,
    isPriceEstimated,
    features,
    isPaid,
    isPrinciple,
  } = {}) {
    this.id = id || undefined;
    this.createdAt = createdAt || undefined;
    this.updatedAt = updatedAt || undefined;
    this.paymentDate = paymentDate || undefined;
    this.paymentMethod = paymentMethod || undefined;
    this.pointsPaid = pointsPaid ?? undefined;
    this.pricePaid = pricePaid ?? undefined;
    this.price = price ?? undefined;
    this.priceCurrency = priceCurrency || undefined;
    this.title = title || undefined;
    this.description = description || undefined;
    this.loyaltyProgram = loyaltyProgram ? { id: loyaltyProgram.id, title: loyaltyProgram.title } : undefined;
    this.isPriceEstimated = isPriceEstimated ?? undefined;
    this.isPaid = isPaid ?? undefined;
    this.features = features || undefined;
    this.isPrinciple = isPrinciple ?? undefined;
  }

  /**
   * The booking segment ID
   * @type String
   */
  id;

  /**
   * When the payment was created on the platform (UTC).
   * @type Date
   */
  createdAt;

  /**
   * When the payment was last modified on the platform (UTC).
   * @type {Date}
   */
  updatedAt;

  /**
   * When the payment was actually made (user timezone).
   * @type {Date|undefined}
   */
  paymentDate;

  /**
   * The method of payment.
   * @type {{id: String}|undefined}
   */
  paymentMethod;

  /**
   * The points paid, if any.
   * @type {Number|undefined}
   */
  pointsPaid;

  /**
   * The price actually paid on card in home currency.
   * @type Number
   */
  pricePaid;

  /**
   * The price paid in the vendor currency
   * @type Number
   */
  price;

  /**
   * The currency code of the `price`. i.e. `GBP`
   * @type String
   */
  priceCurrency;

  /**
   * The title for this payment.
   * @type String
   */
  title;

  /**
   * A description of this payment.
   * @type String
   */
  description;

  /**
   * The Loyalty Program used for redemption
   * @type {{ id: String, title: String }|undefined}
   */
  loyaltyProgram;

  /**
   * If this booking price has been estimated, or confirmed.
   * @type Boolean
   */
  isPriceEstimated;

  /**
   * The features of the booking.
   * @type {String[]|undefined}
   */
  features;

  /**
   * If this booking has been fully paid.
   * @type Boolean
   */
  isPaid;

  /**
   * Indicates if this payment is the primary main payment for the associated booking.
   * @type Boolean
   */
  isPrinciple;

  toJSON() {
    const { error, value } = BookingPaymentValidator.validate(this);
    if (error === undefined) {
      return value;
    }
    console.log(error, value);
    return undefined;
  }

  /**
   * Indicates if this Booking Payment and its data is valid.
   * @return {boolean}
   */
  get isValid() {
    const { error, value } = BookingPaymentValidator.validate(this);
    if (error === undefined) {
      return true;
    }
    console.log(error, value);
    return false;
  }

  /**
   * Indicates if this Booking Payment has any data set, or if it is empty.
   * @return {boolean}
   */
  get isEmpty() {
    if (
      !this.title &&
      !this.description &&
      !this.loyaltyProgram &&
      // TODO: Add other fields
      (!this.features || !this.features.length)
    ) {
      return true;
    }
    return false;
  }
}
