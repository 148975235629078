import calculateDistance from "../../lib/calculateDistance.js";
import BookingEditorReducerGeneral from "./BookingEditorReducerGeneral.js";
import BookingEditorReducerPayments from "./BookingEditorReducerPayments.js";
import emptyPaymentObject from "./emptyPaymentObject.js";
import emptyStayObject from "./emptyStayObject.js";
import emptyTravelObject from "./emptyTravelObject.js";
import PaymentEditorReducerValidator from "./PaymentEditorReducerValidator.js";
import SegmentEditorReducerValidator from "./SegmentEditorReducerValidator.js";
import StayEditorReducerValidator from "./StayEditorReducerValidator.js";
import { integerValidator } from "./validators.js";
import { addDays } from "date-fns";
export function HandleLoyaltyProgramActivityAction(row, action) {
  if (action.type === "ADD_LOYALTY_PROGRAM_ACTIVITY") {
    return {
      ...row,
      loyaltyProgramActivities: [
        ...(row.loyaltyProgramActivities || []),
        {
          loyaltyProgram: action.value,
          points: "",
          tierPoints: "",
          note: "",
          estimatedClearedDateDurationInDays: null,
          estimatedClearedDateDurationInDaysAsString: "",
          estimatedClearedDateDurationInDaysIsValid: true,
          clearDueDate: null,
          source: action.value.sources[0],
          isPending: false,
          isCleared: false,
          isQueryInProgress: false,
        },
      ],
    };
  } else if (action.type === "DELETE_LOYALTY_PROGRAM_ACTIVITY") {
    return {
      ...row,
      loyaltyProgramActivities: (row.loyaltyProgramActivities || []).filter(
        (loyaltyProgramActivity) => loyaltyProgramActivity.loyaltyProgram.id !== action.value.id,
      ),
    };
  } else if (action.type === "SET_LOYALTY_PROGRAM_ACTIVITY") {
    return {
      ...row,
      loyaltyProgramActivities: (row.loyaltyProgramActivities || []).map((loyaltyProgramActivity) => {
        const { id: idToUpdate, ...other } = action.value;
        if (loyaltyProgramActivity.loyaltyProgram.id !== idToUpdate) return loyaltyProgramActivity;
        const nextValue = { ...loyaltyProgramActivity, ...other };

        if (other.estimatedClearedDateDurationInDays) {
          let activityEndDate = row.checkOutDate || row.departureDate;
          if (activityEndDate) {
            nextValue.clearDueDate = addDays(activityEndDate, other.estimatedClearedDateDurationInDays);
          }
        }

        return nextValue;
      }),
    };
  }
}

export function SegmentEditorReducer(segment, action) {
  if (action.type === "SET_TRIP") {
    return segment;
    // return SegmentEditorReducerValidator({ ...segment, tripId: action.value?.id || undefined });
  }

  if (action.type === "SET_LEADING_CARRIER") {
    return SegmentEditorReducerValidator({
      ...segment,
      isLeadingCarrier: segment.id === action.id && action.value === true,
    });
  }

  if (segment.id !== action.id) return segment;

  const handleLoyaltyProgramActivityActionResult = HandleLoyaltyProgramActivityAction(segment, action);

  if (handleLoyaltyProgramActivityActionResult) {
    return SegmentEditorReducerValidator(handleLoyaltyProgramActivityActionResult);
  }

  const newData = {
    ...segment,
    ...action.value,
  };

  if (action.value?.origin || action.value?.destination) {
    let origin = action.value?.origin || segment.origin;
    let destination = action.value?.destination || segment.destination;

    newData.distanceInKm = calculateDistance(origin, destination);
  }

  return SegmentEditorReducerValidator(newData);
}

export function PaymentEditorReducer(payment, action) {
  if (payment.id !== action.id) return payment;

  let newData = {
    ...payment,
    ...action.value,
  };

  if (
    action.value.isPriceEstimated === true &&
    !payment.isPriceEstimated &&
    !payment.pricePaidAsString?.length &&
    payment.availableConversion
  ) {
    // price estimation is being turned on, and the field is empty, and a conversion is available
    newData = { ...newData, ...payment.availableConversion, availableConversion: undefined };
  }

  return PaymentEditorReducerValidator(newData);
}

export function StayEditorReducer(stay, action) {
  if (action.type === "SET_TRIP") {
    return StayEditorReducerValidator({ ...stay, tripId: action.value?.id || undefined });
  }

  if (stay.id !== action.id) return stay;

  const handleLoyaltyProgramActivityActionResult = HandleLoyaltyProgramActivityAction(stay, action);

  if (handleLoyaltyProgramActivityActionResult) {
    return StayEditorReducerValidator(handleLoyaltyProgramActivityActionResult);
  }

  return StayEditorReducerValidator({
    ...stay,
    ...action.value,
  });
}

export default function BookingEditorReducer(state, action) {
  if (!action) return state;
  const { type, value } = action;
  let nextState = state;

  const PaymentReducerMap = (payment) => PaymentEditorReducer(payment, action);
  const SegmentReducerMap = (segment) => SegmentEditorReducer(segment, action);
  const StayReducerMap = (segment) => StayEditorReducer(segment, action);

  if (type === "DELETE_SEGMENT") {
    nextState = BookingEditorReducerGeneral({
      ...state,
      segments: state.segments.filter((segment) => segment.id !== action.id),
    });
    nextState.segmentsToDelete = [...(nextState.segmentsToDelete || []), action.id];
    if (nextState.segments.length && !nextState.segments.find((segment) => segment.isLeadingCarrier)) {
      nextState.segments[0] = { ...nextState.segments[0], isLeadingCarrier: true };
    }
  } else if (type === "DELETE_STAY") {
    nextState = BookingEditorReducerGeneral({
      ...state,
      stays: state.stays.filter((stay) => stay.id !== action.id),
    });
    nextState.staysToDelete = [...(nextState.staysToDelete || []), action.id];
  } else if (type === "SET_STATE") {
    nextState = BookingEditorReducerGeneral({ ...state, ...value });
  } else if (type === "SET_TRIP") {
    nextState = BookingEditorReducerGeneral({
      ...state,
      trip: value,
      segments: state.segments.map(SegmentReducerMap),
      stays: state.stays.map(StayReducerMap),
    });
  } else if (type === "SET_SEGMENT" || type === "SET_LEADING_CARRIER") {
    nextState = BookingEditorReducerGeneral({ ...state, segments: state.segments.map(SegmentReducerMap) });
  } else if (type === "SET_PAYMENT") {
    nextState = BookingEditorReducerGeneral(
      BookingEditorReducerPayments({ ...state, payments: state.payments.map(PaymentReducerMap) }),
    );
  } else if (type === "DELETE_PAYMENT") {
    nextState = BookingEditorReducerGeneral(
      BookingEditorReducerPayments({ ...state, payments: state.payments.filter((item) => item.id !== action.id) }),
    );
  } else if (
    type === "ADD_LOYALTY_PROGRAM_ACTIVITY" ||
    type === "SET_LOYALTY_PROGRAM_ACTIVITY" ||
    type === "DELETE_LOYALTY_PROGRAM_ACTIVITY"
  ) {
    nextState = BookingEditorReducerGeneral({
      ...state,
      segments: state.segments?.map(SegmentReducerMap),
      stays: state.stays?.map(StayReducerMap),
    });
  } else if (type === "SET_STAY") {
    nextState = BookingEditorReducerGeneral({ ...state, stays: state.stays.map(StayReducerMap) });
  } else if (type === "ADD_SEGMENT") {
    const hasNoSegments = state.segments.length === 0;
    const lastSegment = hasNoSegments ? null : state.segments[state.segments.length - 1];
    nextState = BookingEditorReducerGeneral({
      ...state,
      segments: [
        ...state.segments,
        emptyTravelObject({
          bookingId: state.id,
          isLeadingCarrier: hasNoSegments,
          carrier: lastSegment?.carrier,
          origin: lastSegment?.destination,
          departureDate: lastSegment?.departureDate,
          originTitle: (lastSegment?.destination?.id ? "" : lastSegment?.destinationTitle) || "",
          distanceInKm: lastSegment.distanceInKm,
        }),
      ],
    });
  } else if (type === "ADD_STAY") {
    nextState = BookingEditorReducerGeneral({
      ...state,
      stays: [...state.stays, emptyStayObject({ bookingId: state.id })],
    });
  } else if (type === "ADD_PAYMENT") {
    const templateItem = state.payments.find((item) => item.isPrinciple);
    const newItem = emptyPaymentObject();

    if (templateItem) {
      newItem.priceCurrency = templateItem.priceCurrency;
      newItem.isPaid = templateItem.isPaid;
      newItem.isPriceEstimated = templateItem.isPriceEstimated;
      newItem.paymentMethod = templateItem.paymentMethod;
    } else {
      newItem.isPrinciple = true;
    }
    nextState = BookingEditorReducerPayments(
      BookingEditorReducerGeneral({
        ...state,
        payments: [...state.payments, newItem],
      }),
    );
  }

  return nextState;
}
