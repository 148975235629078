import React from "react";
import usePaymentMethods from "../../contexts/PaymentMethods/usePaymentMethods.js";
import CreatableAutocomplete from "./CreatableAutocomplete.js";

export default function PaymentMethodsAutocomplete({ value, onChange, label, disabled, sx }) {
  const { paymentMethods } = usePaymentMethods();

  return (
    <CreatableAutocomplete
      value={value}
      onChange={onChange}
      options={paymentMethods}
      label={label || "Payment Method"}
      variant="outlined"
      clearOnBlur
      useValue
      disabled={disabled}
      fullWidth={false}
      sx={sx}
    />
  );
}
