import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import React, { useRef, useState, useEffect } from "react";
import UploadFile from "@mui/icons-material/UploadFile";
import useSnackBar from "../../contexts/SnackBar/useSnackBar.js";
import { importAsEJSON, importData } from "../../models/extractToJSON.js";
import resetDatabase from "../../models/resetDatabase.js";

export default function ImportDataMenuItem({ onClick }) {
  const { enqueueSnackbar } = useSnackBar();

  const fileInputField = useRef(null);
  const [file, setFile] = useState({ name: "", text: "" });
  const [count, setCount] = useState(0);

  const onSelectFileClick = () => {
    fileInputField.current.click();
  };

  const onClickHandler = async () => {
    onClick();
    onSelectFileClick();
  };

  const processFile = async (value, fileName) => {
    const result = await importAsEJSON(value);
    await resetDatabase();
    await importData(result);
    setFile({ name: "", text: "" });
    enqueueSnackbar({
      message: `"${fileName}" imported`,
      variant: "success",
    });
    setCount(count + 1);
  };

  useEffect(() => {
    if (!file.text) return;
    processFile(file.text, file.name)
      .then(() => {
        // ignore
      })
      .catch(() => {
        // ignore
      });
  }, [file.text]);

  const onFileSelectionChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        setFile({ name: file.name, text: reader.result });
      },
      false,
    );

    reader.readAsText(file);
  };

  return (
    <MenuItem onClick={onClickHandler} disableRipple disableTouchRipple>
      <Box
        component="input"
        type="file"
        ref={fileInputField}
        key={count.toString()}
        sx={{
          visibility: "hidden",
          height: 0,
          margin: 0,
          width: 0,
          padding: 0,
        }}
        accept={"text/json"}
        onChange={onFileSelectionChange}
      />
      <ListItemIcon>
        <UploadFile fontSize="small" />
      </ListItemIcon>
      <ListItemText>Import Data</ListItemText>
    </MenuItem>
  );
}
