import { db } from "../../models/db.js";
import calculateDistance from "../calculateDistance.js";

export default async function migrateBookingSegmentStationData() {
  await db.transaction("rw", [db.bookings, db.stations, db.segments, db.flights], async () => {
    const stations = await db.stations.toArray();

    const modifySegment = (flight) => {
      const origin = stations.find((station) => station.id === flight.origin.id);
      const destination = stations.find((station) => station.id === flight.destination.id);

      if (origin && destination) {
        flight.origin = {
          id: origin.id,
          title: origin.title,
          timeZone: origin.timeZone,
          lat: origin.lat ?? undefined,
          lon: origin.lon ?? undefined,
        };

        flight.destination = {
          id: destination.id,
          title: destination.title,
          timeZone: destination.timeZone,
          lat: destination.lat ?? undefined,
          lon: destination.lon ?? undefined,
        };

        flight.distanceInKm = calculateDistance(flight.origin, flight.destination);
      }
    };

    await db.flights.toCollection().modify(modifySegment);
    await db.segments.toCollection().modify(modifySegment);
    await db.bookings.toCollection().modify((booking) => {
      if (booking.segments) {
        booking.segments = booking.segments.map((segment) => {
          modifySegment(segment);
          return segment;
        });
      }
    });
  });
}
