import Joi from "joi";

export const AircraftValidator = Joi.object({
  id: Joi.string().required(),
  model: Joi.string().required().example("Boeing 787-9"),
  modeS: Joi.string().optional().example("E80459"),
  reg: Joi.string().optional().example("CC-BGM"),
}).label("Aircraft");

export default AircraftValidator;
