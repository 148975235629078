import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { useIntl } from "react-intl";
import useSnackBar from "../../contexts/SnackBar/useSnackBar.js";
import { db } from "../../models/db.js";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import RefreshIcon from "@mui/icons-material/Refresh";
import SetTimeZoneButton from "./SetTimeZoneButton.js";

export default function StayBrandsLocationsTableRow({ stayBrandsLocation }) {
  const { enqueueSnackbar } = useSnackBar();
  const { formatNumber } = useIntl();

  const [editMode, setEditMode] = useState(false);
  const [title, setTitle] = useState(stayBrandsLocation.title);
  const [timeZone, setTimeZone] = useState(stayBrandsLocation.timeZone || null);

  const onEditClick = () => {
    setEditMode(!editMode);
  };

  const onDeleteClick = async () => {
    const result = await db.deleteStayBrandLocation(stayBrandsLocation.id);
    if (result?.success) {
      enqueueSnackbar({ message: `${title} Deleted`, variant: "success" });
    } else {
      enqueueSnackbar({ message: result?.message || "Unknown Error", variant: "error" });
    }
  };

  const onCancelClick = () => {
    setEditMode(false);
    setTitle(stayBrandsLocation.title);
  };

  const onSaveClick = async (data = {}) => {
    await db.updateStayBrandLocation(
      {
        // title,
        timeZone,
        ...data,
      },
      stayBrandsLocation,
    );
    enqueueSnackbar({ message: `${title} Updated`, variant: "success" });
    if (editMode) setEditMode(!editMode);
  };

  const onTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const onTimeZoneChangeClick = (value) => {
    setTimeZone(value);
    if (value && !editMode) {
      // apply the change immediately
      onSaveClick({ timeZone: value }).then(() => {
        // ignore
      });
    }
  };

  const onRecalculateDataClick = async () => {
    await db.recalculateStayBrandLocation(stayBrandsLocation.id);
    enqueueSnackbar({ message: `${title} Data Updated`, variant: "success" });
  };

  return (
    <TableRow hover>
      {!editMode && <TableCell>{stayBrandsLocation.title}</TableCell>}
      {editMode && (
        <TableCell>
          <TextField value={title} fullWidth margin="none" required disabled onChange={onTitleChange} />
        </TableCell>
      )}

      <TableCell>
        <SetTimeZoneButton onChange={onTimeZoneChangeClick} value={timeZone} />
      </TableCell>

      <TableCell align="right">
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1}>
          {stayBrandsLocation.totalNights !== undefined ? (
            <span>{formatNumber(stayBrandsLocation.totalNights)}</span>
          ) : (
            <span>Unknown</span>
          )}

          <IconButton size="small" disabled={editMode} color="inherit" onClick={onRecalculateDataClick}>
            <RefreshIcon fontSize="small" />
          </IconButton>
        </Stack>
      </TableCell>

      <TableCell align="right">
        {editMode && (
          <Button color="secondary" onClick={onCancelClick}>
            Cancel
          </Button>
        )}
        {!editMode && (
          <Button color="secondary" onClick={onDeleteClick}>
            Delete
          </Button>
        )}
        {!editMode && (
          <Button color="secondary" onClick={onEditClick}>
            Edit
          </Button>
        )}
        {editMode && (
          <Button color="primary" onClick={onSaveClick}>
            Save
          </Button>
        )}
      </TableCell>
    </TableRow>
  );
}
