import { STAY, TRAVEL } from "../../models/BookingConstants.js";

export default function BookingEditorReducerValidator(state) {
  if (state.type === TRAVEL && !state.segments?.length) return false;
  if (state.type === STAY && !state.stays?.length) return false;

  if (state.segments?.length) {
    const nonEmptySegments = state.segments.filter((segment) => segment.isEmpty === false);
    if (!nonEmptySegments.length) return false;
    if (nonEmptySegments.find((segment) => segment.isValid === false)) return false;
  }

  if (state.stays?.length) {
    const nonEmptyStays = state.stays.filter((stay) => stay.isEmpty === false);
    if (!nonEmptyStays.length) return false;
    if (nonEmptyStays.find((stay) => stay.isValid === false)) return false;
  }

  if (!state.payments?.length) return false;

  const invalidPayment = state.payments.find(
    (payment) =>
      payment.priceIsValid === false || payment.pricePaidIsValid === false || payment.pointsPaidIsValid === false,
  );
  if (invalidPayment) {
    return false;
  }

  return true;
}
