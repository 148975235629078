import LoyaltyProgramActivityValidator from "./LoyaltyProgramActivityValidator.js";

/**
 * Represents an activity in a Loyalty Program, such as a points earning or redemption event.
 */
export default class LoyaltyProgramActivity {
  constructor({
    loyaltyProgram,
    points,
    tierPoints,
    rewardValue,
    isPending,
    isCleared,
    isQueryInProgress,
    source,
    note,
    clearDueDate,
    clearedDate,
    pointsExpireDate,
    tierPointsExpireDate,
    estimatedClearedDateDurationInDays,
  } = {}) {
    this.source = source || undefined;
    this.points = points?.length === 0 ? undefined : (points ?? undefined);
    this.loyaltyProgram = loyaltyProgram
      ? { id: loyaltyProgram.id, title: loyaltyProgram.title, sources: loyaltyProgram.sources }
      : undefined;
    this.tierPoints = tierPoints?.length === 0 ? undefined : (tierPoints ?? undefined);
    this.rewardValue = rewardValue?.length === 0 ? undefined : (rewardValue ?? undefined);
    this.isPending = isPending ?? undefined;
    this.isCleared = isCleared ?? undefined;
    this.isQueryInProgress = isQueryInProgress ?? undefined;
    this.note = note || undefined;
    this.clearDueDate = clearDueDate || undefined;
    this.clearedDate = clearedDate || undefined;
    this.pointsExpireDate = pointsExpireDate || undefined;
    this.tierPointsExpireDate = tierPointsExpireDate || undefined;
    this.estimatedClearedDateDurationInDays = estimatedClearedDateDurationInDays ?? undefined;
  }

  /**
   * The Loyalty Program. A Segment or Stay can have more than one activity, but only one activity per Loyalty Program.
   * @type {{id: String, title: String, sources: String[]}}
   */
  loyaltyProgram;

  /**
   * The Loyalty Program Source
   * @type {String}
   */
  source;

  /**
   * Optional free text.
   * @type {String}
   */
  note;

  /**
   * @type {Number|undefined}
   */
  points;

  /**
   * @type {Number|undefined}
   */
  tierPoints;

  /**
   * @type {Number|undefined}
   */
  rewardValue;

  /**
   * @type {Boolean|undefined}
   */
  isPending;

  /**
   * Is the program showing this activity as 'cleared'.
   * @type {Boolean|undefined}
   */
  isCleared;

  /**
   * If a dispute or missing claim query is in progress with the program.
   * @type {Boolean|undefined}
   */
  isQueryInProgress;

  /**
   * When this award is expected to clear
   * @type {Date|undefined}
   */
  clearDueDate;

  /**
   * When this award was confirmed, cleared.
   * @type {Date|undefined}
   */
  clearedDate;

  /**
   * The number of days from the activity date, that the award should have cleared.
   * @type {Number|undefined}
   */
  estimatedClearedDateDurationInDays;

  /**
   * When the points award (if any) will expire.
   * @type {Date|undefined}
   */
  pointsExpireDate;

  /**
   * When the tier points award (if any) will expire.
   * @type {Date|undefined}
   */
  tierPointsExpireDate;

  toJSON() {
    const { error, value } = LoyaltyProgramActivityValidator.validate(this);
    if (error === undefined) {
      return value;
    }
    console.log(error, value);
    return undefined;
  }

  /**
   * Indicates if the Loyalty Program Activity and its data are valid.
   * @return {boolean}
   */
  get isValid() {
    const { error, value } = LoyaltyProgramActivityValidator.validate(this);
    if (error === undefined) {
      return true;
    }
    console.log(error, value);
    return false;
  }
}
