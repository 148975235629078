import Typography from "@mui/material/Typography";
import React from "react";
import { useIntl } from "react-intl";
import { STAY, TRAVEL } from "../../models/BookingConstants.js";
import { isSameDay, startOfDay, isEqual, subSeconds } from "date-fns";
import { TZDate } from "@date-fns/tz";
import ShortRangeText from "./ShortRangeText.js";
import StatusLabel from "./StatusLabel.js";

export function CancelInformationText({ booking, showLineBreak }) {
  const { formatDate } = useIntl();

  if (!booking.cancelBeforeDate) return null;

  const midnightMorningOnDay = startOfDay(new TZDate(booking.cancelBeforeDate, "UTC"));
  const isCancelAtMidnight = isEqual(booking.cancelBeforeDate, midnightMorningOnDay);

  const cancelBeforeDateToDisplay = isCancelAtMidnight
    ? subSeconds(booking.cancelBeforeDate, 1)
    : booking.cancelBeforeDate;

  const text = `Cancel before ${formatDate(cancelBeforeDateToDisplay, {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "short",
    timeZone: "UTC",
    // timeZoneName: "short",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  })}`;

  if (!showLineBreak) return text;
  return (
    <>
      <br />
      {text}
    </>
  );
}

export function ShortDate({ date }) {
  const { formatDate } = useIntl();
  return formatDate(date, {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "short",
  });
}

function StayBookingDescriptionText({ booking, showPerNight, showRoomNumber }) {
  const { formatNumber } = useIntl();
  const stay = booking.stays?.[0];

  if (!stay) return null;

  const pricePerNightText = () => {
    if (!showPerNight || !booking.pricePaidPerNight) return "";

    if (stay.nights > 1) {
      return ` (~${formatNumber(booking.pricePaidPerNight, { style: "currency", currency: "GBP", maximumFractionDigits: 0 })} per night)`;
    }

    return ` (${formatNumber(booking.pricePaidPerNight, { style: "currency", currency: "GBP", maximumFractionDigits: 0 })})`;
  };

  const perNightConst = pricePerNightText();

  return (
    <Typography component="div" variant="body2" color="secondary">
      <StatusLabel booking={booking} />
      {stay.nights > 0 ? `${stay.nights} ${stay.nights === 1 ? "night" : "nights"}${perNightConst}; ` : null}
      <ShortRangeText start={stay.checkInDate} end={stay.checkOutDate} />
      {showRoomNumber && stay.roomNumber ? `; Room ${stay.roomNumber}` : null}
      <CancelInformationText booking={booking} showLineBreak />
    </Typography>
  );
}

function TravelBookingDescriptionText({ booking }) {
  const numberOfSegments = booking.segments?.length || 0;
  if (!numberOfSegments) return null;

  if (numberOfSegments === 1) {
    // just force the display for single segments
    return (
      <Typography component="div" variant="body2" color="secondary">
        <StatusLabel booking={booking} />
        <ShortDate date={booking.segments[0].departureDate} />
      </Typography>
    );
  }

  if (booking.isReturn) {
    return (
      <Typography component="div" variant="body2" color="secondary">
        <StatusLabel booking={booking} />
        <ShortRangeText start={booking.startDate} end={booking.endDate} />
      </Typography>
    );
  }

  // TODO: Determine if getting from segments is better

  if (booking.startDate && booking.endDate && !isSameDay(booking.startDate, booking.endDate)) {
    return (
      <Typography component="div" variant="body2" color="secondary">
        <StatusLabel booking={booking} />
        <ShortRangeText start={booking.startDate} end={booking.endDate} />
      </Typography>
    );
  }

  if (booking.startDate) {
    return (
      <Typography component="div" variant="body2" color="secondary">
        <StatusLabel booking={booking} />
        <ShortDate date={booking.startDate} />
      </Typography>
    );
  }

  // TODO: Add other descriptions here

  return null;
}

export default function BookingDescriptionText({ booking, showPerNight, showRoomNumber }) {
  if (booking.type.includes(STAY)) {
    return <StayBookingDescriptionText booking={booking} showPerNight={showPerNight} showRoomNumber={showRoomNumber} />;
  }
  if (booking.type.includes(TRAVEL)) {
    return <TravelBookingDescriptionText booking={booking} />;
  }
  return null;
}
