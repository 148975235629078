import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import TimeZoneAutoComplete from "./TimeZoneAutoComplete.js";

export default function TimeZonePickerModal({ isOpen, onClose, value }) {
  const [modifiedValue, setModifiedValue] = useState(value);

  const onPrimaryClick = async () => {
    onClose(modifiedValue?.timeZone);
  };

  const onCloseClick = () => {
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onCloseClick} fullWidth maxWidth={"xs"}>
      <DialogTitle>Select Time Zone</DialogTitle>
      <DialogContent>
        <Stack direction="row" spacing={2}>
          <TimeZoneAutoComplete value={modifiedValue} onChange={setModifiedValue} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseClick} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={onPrimaryClick} startIcon={<AddIcon fontSize="small" />} color="primary" variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
