import { BOOKED, STAY, TRAVEL } from "../../models/BookingConstants.js";

function calculateLoyaltyTotals(items) {
  const result = items.reduce(
    (acc, { loyaltyProgramActivity }) => {
      if (!loyaltyProgramActivity) return acc;
      acc.points += loyaltyProgramActivity.points || 0;
      acc.tierPoints += loyaltyProgramActivity.tierPoints || 0;

      return acc;
    },
    {
      points: 0,
      tierPoints: 0,
    },
  );

  return result;
}

export default function groupBookingsByLoyaltyProgram({ bookings }) {
  const itemsByLoyaltyProgram = bookings
    .map((booking) => {
      return [
        ...(booking.segments || [])
          .map((item) => ({
            item,
            booking,
            type: TRAVEL,
          }))
          .filter((item) => item.booking.status === BOOKED),
        ...(booking.stays || [])
          .map((item) => ({
            item,
            booking,
            type: STAY,
          }))
          .filter((item) => item.booking.status === BOOKED),
      ];
    })
    .flat()
    .map(({ item, booking, type }) => {
      if (!item.loyaltyProgramActivities?.length) {
        // this item has no programs attached
        return [{ loyaltyProgramActivity: null, item, booking, type }];
      }
      return item.loyaltyProgramActivities.map((loyaltyProgramActivity) => ({
        loyaltyProgramActivity,
        item,
        booking,
        type,
      }));
    })
    .flat()
    .sort((a, b) => {
      let start = a.item.checkInDate || a.item.departureDate;
      let end = b.item.checkInDate || b.item.departureDate;
      if (!start) start = a.booking.bookingDate;
      if (!end) end = b.booking.bookingDate;

      return start.getTime() - end.getTime();
    });

  const result = Object.entries(
    Object.groupBy(
      itemsByLoyaltyProgram,
      ({ loyaltyProgramActivity }) => loyaltyProgramActivity?.loyaltyProgram?.id || "NONE",
    ),
  )
    .map(([key, value]) => {
      const loyaltyTotals = calculateLoyaltyTotals(value);
      return {
        id: key,
        items: value,
        loyaltyProgram: value[0]?.loyaltyProgramActivity?.loyaltyProgram,
        tierPoints: loyaltyTotals.tierPoints,
        points: loyaltyTotals.points,
      };
    })
    .sort((a, b) => {
      const aTitle = a.loyaltyProgram?.title || "";
      const bTitle = b.loyaltyProgram?.title || "";

      return aTitle.localeCompare(bTitle, "en", { sensitivity: "base" });
    });

  return result;
}
