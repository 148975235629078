import Typography from "@mui/material/Typography";
import React from "react";
import { useIntl } from "react-intl";

export default function SegmentDistanceLabel({ segment, inline }) {
  const { formatNumber } = useIntl();

  const sx = inline ? { paddingLeft: 1, whiteSpace: "nowrap" } : { paddingRight: 1, whiteSpace: "nowrap" };

  if (segment.distanceInKm) {
    return (
      <Typography component="span" variant="body2" color="secondary.main" sx={sx}>
        {formatNumber(segment.distanceInKm, {
          style: "unit",
          unit: "kilometer",
          maximumFractionDigits: 0,
        })}
      </Typography>
    );
  }

  return null;
}
