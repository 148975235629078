import React from "react";
import BookingPaymentEditorRow from "./BookingPaymentEditorRow.js";

export default function BookingPayments({ state, setBookingPayment }) {
  return (
    <>
      {state.payments?.map((payment) => (
        <BookingPaymentEditorRow
          payment={payment}
          key={payment.id}
          setBookingPayment={setBookingPayment}
          referenceDate={state.suggestedDepartureDate}
          endDateMaxValue={state.endDateMaxValue}
          bookingDateMinValue={state.bookingDateMinValue}
        />
      ))}
    </>
  );
}
