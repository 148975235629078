import Tooltip from "@mui/material/Tooltip";
import React from "react";
import IconButton from "@mui/material/IconButton";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";
import PublicIcon from "@mui/icons-material/Public";

const to = "/globe";
export default function GlobalButton() {
  const { pathname } = useLocation();
  const isActive = pathname === to;
  return (
    <Tooltip title={"Lifetime flights map"} placement="bottom-end">
      <IconButton component={ReactRouterLink} to={to} color={isActive ? "primary" : "inherit"} size="small">
        <PublicIcon />
      </IconButton>
    </Tooltip>
  );
}
