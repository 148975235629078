import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import AddLoyaltyProgramTab from "./AddLoyaltyProgramTab.js";
import LoyaltyProgramActivityRow from "./LoyaltyProgramActivityRow.js";
import Dialog from "@mui/material/Dialog";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const NEW_TAB = "new";

export default function LoyaltyProgramActivityModal({
  isOpen,
  onClose,
  title,
  set,
  loyaltyProgramActivities,
  referenceDate,
  minDate,
}) {
  const [tab, setTab] = useState(loyaltyProgramActivities[0]?.loyaltyProgram?.id || NEW_TAB);

  const setLoyaltyProgramActivity = (value, type) => {
    set(value, type);
  };

  const activityCount = loyaltyProgramActivities?.length || 0;
  const hasActivities = activityCount > 0;

  const deleteActivity = (id) => {
    const nextActivity = loyaltyProgramActivities.find((item) => item.loyaltyProgram.id !== id);
    setTab(nextActivity?.loyaltyProgram?.id || NEW_TAB);
    setLoyaltyProgramActivity({ id }, "DELETE_LOYALTY_PROGRAM_ACTIVITY");
  };

  const setActiveTab = (event, newValue) => {
    setTab(newValue);
  };

  const activeLoyaltyProgramInView =
    tab === NEW_TAB ? null : loyaltyProgramActivities.find((item) => item.loyaltyProgram.id === tab);

  const onAdd = (selectedLoyaltyProgram) => {
    set(selectedLoyaltyProgram, "ADD_LOYALTY_PROGRAM_ACTIVITY");
    setTab(selectedLoyaltyProgram.id);
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth={"lg"}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close" size="medium">
            <CloseIcon fontSize="small" />
          </IconButton>
          <Typography variant="h6" sx={{ flex: "1", marginLeft: 2 }}>
            {title || "Loyalty Programs"}
          </Typography>
        </Toolbar>
      </AppBar>

      <Tabs value={tab} onChange={setActiveTab}>
        {loyaltyProgramActivities.map((loyaltyProgramActivity) => (
          <Tab
            disableRipple
            key={loyaltyProgramActivity.loyaltyProgram.id}
            value={loyaltyProgramActivity.loyaltyProgram.id}
            label={loyaltyProgramActivity.loyaltyProgram.title}
          />
        ))}
        <Tab disableRipple label="New" value={NEW_TAB} />
      </Tabs>
      {tab === NEW_TAB && (
        <AddLoyaltyProgramTab
          onAdd={onAdd}
          isEmpty={!hasActivities}
          selectedIds={loyaltyProgramActivities.map((item) => item.loyaltyProgram.id)}
        />
      )}
      {activeLoyaltyProgramInView && (
        <LoyaltyProgramActivityRow
          loyaltyProgramActivity={activeLoyaltyProgramInView}
          setLoyaltyProgramActivity={setLoyaltyProgramActivity}
          referenceDate={referenceDate}
          minDate={minDate}
          deleteActivity={deleteActivity}
        />
      )}

      {/*<Divider />*/}
      {/*<DialogActions sx={{ justifyContent: "flex-start" }}>*/}
      {/*  <Button onClick={onPrimaryClick} startIcon={<Save />} color="primary" variant="contained">*/}
      {/*    Update*/}
      {/*  </Button>*/}
      {/*  <Button startIcon={<CloseIcon />} onClick={onClose} color="secondary" variant="outlined">*/}
      {/*    Close*/}
      {/*  </Button>*/}

      {/*  <Divider orientation="vertical" variant="middle" flexItem />*/}

      {/*  {isSingleActivity && (*/}
      {/*    <Button*/}
      {/*      startIcon={<DeleteForeverIcon />}*/}
      {/*      disabled={hasMultipleActivities}*/}
      {/*      color="error"*/}
      {/*      variant="outlined"*/}
      {/*      onClick={onDeleteSingleActivity}*/}
      {/*    >*/}
      {/*      Delete*/}
      {/*    </Button>*/}
      {/*  )}*/}
      {/*</DialogActions>*/}
    </Dialog>
  );
}
