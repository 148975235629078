import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import useAppInstallation from "../../contexts/AppInstallation/useAppInstallation.js";
import useSnackBar from "../../contexts/SnackBar/useSnackBar.js";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";

export default function CheckForUpdatesMenuItem({ onClick }) {
  const { enqueueSnackbar } = useSnackBar();
  const { checkAndInstallAnyUpdated } = useAppInstallation();
  const performCheck = async () => {
    try {
      const result = await checkAndInstallAnyUpdated();
      enqueueSnackbar({ message: `Update Check Started`, variant: "success" });
    } catch (ex) {
      console.log(ex);
      enqueueSnackbar({ message: `Update Check Failed.`, variant: "error" });
    }
  };

  const onClickHandler = async () => {
    onClick();
    await performCheck();
  };

  return (
    <MenuItem onClick={onClickHandler} disableRipple disableTouchRipple>
      <ListItemIcon>
        <SystemUpdateAltIcon />
      </ListItemIcon>
      <ListItemText primary="Check For Updates" />
    </MenuItem>
  );
}
